export const config = {
    api: '//api.gelielts.cn',
    server: '//',
    serverIelts: '//learn.gelielts.cn',
    staff: '//',
    frontend: '//',
    angularIelts: '//old.gelielts.cn',
    frontendIelts: '//gelielts.cn',
};

export const appConstants = {
    ...config,
    server: config.server === '//' ? config.serverIelts : config.server,
};

export const PAGINATION_LIMITS = [20, 50, 100];

export const ERRORS = {
    required: 'Cannot be empty.',
    same: 'Cannot be the same.',
    url: 'Invalid url.',
    email: 'Invalid email.',
    passwordMustContainNumber: 'Password should contain at least one number.',
    passwordMustContainUpperCaseLetter: 'Password should contain at least one upper case letter.',
    passwordMustContainLowerCaseLetter: 'Password should contain at least one lower case letter.',
    passwordMustContainSpecialCharacter: 'Password should contain at least one special character.',
    passwordMustContainCharacters: 'Password should contain at least 10 characters.',
    passwordsDoNotMatch: 'Passwords do not match.',
};

export const TIME = {
    inputDebounce: 50,
    testInputDebounce: 100,
};
