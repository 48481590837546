import { useFormik } from 'formik';
import _ from 'lodash';
import React, { InputHTMLAttributes, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseButton2Svg from 'src/components/Icons/CloseButton2Svg';
import SwitchIosOffSvg from 'src/components/Icons/SwitchIosOffSvg';
import SwitchIosOnSvg from 'src/components/Icons/SwitchIosOnSvg';
import InputPassword from 'src/components/InputPassword';
import InputPhone from 'src/components/InputPhone';
import InputText from 'src/components/InputText';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import SettingsModal2FA, {
    SettingsModal2FAType,
} from 'src/components/Modals/SettingsModal/SettingsModal2FA';
import SettingsModalValidationError from 'src/components/Modals/SettingsModal/SettingsModalValidationError';
import SuccessModal from 'src/components/Modals/SuccessModal';
import { ERRORS } from 'src/constants';
import { getNoEmpty } from 'src/helpers/getNoEmpty';
import { getIPInfo } from 'src/redux/ipInfo/actions';
import { selectIPInfo } from 'src/redux/ipInfo/selectors';
import { getLayout } from 'src/redux/layout/actions';
import { selectLayout } from 'src/redux/layout/selectors';
import SelectMenu from 'src/components/SelectMenu';
import { useTranslation } from 'react-i18next';
import {
    delete2FAPhoneNumber,
    put2FAPhoneNumber,
    putSettings,
    PutSettingsType,
    putSettingsValidationErrors,
} from 'src/redux/settings/actions';
import { resetUserPassword } from 'src/redux/users/actions';
import * as yup from 'yup';
import { API } from 'src/api';
import moment from 'moment';
import Button from '../../Button';
import Modal from '../../Modal';
import 'moment/locale/es';
import 'moment/locale/pt';

import './styles.scss';

const languages = [
    {
        name: 'English',
        key: 'en',
    },
    {
        name: 'Portuguese',
        key: 'pt',
    },
    {
        name: 'Spanish',
        key: 'es',
    },
    {
        name: 'Chinese',
        key: 'cn',
    },
    {
        name: 'French',
        key: 'fr',
    },
];

const SettingsModal = ({
    open = true,
    setOpen,
}: {
    open: boolean;
    setOpen: (open: boolean) => void;
}) => {
    const { i18n, t } = useTranslation();
    const [selectedLang, setSelectedLang] = useState('en');
    const dispatch = useDispatch();
    const { layout } = useSelector(selectLayout);
    const [twoFactorAuthStatus, setTwoFactorAuthStatus] = useState(!!layout.user.phoneNumber);
    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
    useEffect(() => {
        setTwoFactorAuthStatus(!!layout.user.phoneNumber);
    }, [layout.user.phoneNumber]);
    const [settingsModal2FA, setSettingsModal2FA] = useState<SettingsModal2FAType['state']>(null);
    const [phoneNumberBlur, setPhoneNumberBlur] = useState(false);
    const [settingsModal2FAPhoneNumber, setSettingsModal2FAPhoneNumber] = useState<null | string>(
        null,
    );
    const [ipCountryCode, setIpCountryCode] = useState();
    const IPInfo = useSelector(selectIPInfo);
    const onClose = () => {
        setOpen(false);
    };
    const [openResetPassword, setOpenResetPassword] = useState(null as any);
    const [successResetPassword, setSuccessResetPassword] = useState<string | null>(null);
    const [resetFormikForm, setResetFormikForm] = useState(false);
    const lang = localStorage.getItem('lang');

    useEffect(() => {
        if (lang) {
            setSelectedLang(JSON.parse(lang));
        }
    }, [lang]);

    const emitPhone2FA = (values: (typeof formik)['initialValues']) => {
        if (
            values.phoneNumber?.replaceAll(' ', '') !==
                (layout.user.phoneNumber?.replaceAll(' ', '') || '') &&
            twoFactorAuthStatus
        ) {
            dispatch(
                put2FAPhoneNumber({
                    number: values.phoneNumber || '',
                    cb: () => {
                        setSettingsModal2FAPhoneNumber(values.phoneNumber);
                        setSettingsModal2FA('enterCode');
                        setResetFormikForm(!resetFormikForm);
                    },
                }),
            );
        } else {
            setOpenSuccessModal(true);
        }
    };

    const formik = useFormik({
        initialValues: {
            email: layout.user.mail,
            passwordNew: '',
            passwordNewConfirmation: '',
            passwordCurrent: '',
            phoneNumber: layout.user.phoneNumber || '',
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            dispatch(putSettingsValidationErrors(false));
            if (values.email !== layout.user.mail || !_.isEmpty(values.passwordNew)) {
                const payload: PutSettingsType = _.omitBy(
                    {
                        newMail: values.email,
                        newPass: values.passwordNew,
                        pass: values.passwordCurrent,
                    },
                    _.isEmpty,
                );
                payload.cb = () => {
                    dispatch(getLayout());
                    emitPhone2FA(values);
                    formik.resetForm();
                };
                dispatch(putSettings(payload));
            } else {
                emitPhone2FA(values);
            }
            if (!twoFactorAuthStatus) {
                dispatch(
                    delete2FAPhoneNumber({
                        cb: () => {
                            dispatch(getLayout());
                            formik.setFieldValue('phoneNumber', '');
                        },
                    }),
                );
            }
        },
        validationSchema: yup.object().shape({
            email: yup.string().required(ERRORS.required).email(ERRORS.email),
            passwordNew: yup
                .string()
                .trim()
                .min(10, ERRORS.passwordMustContainCharacters)
                .matches(/\d/, ERRORS.passwordMustContainNumber)
                .matches(/[A-Z]/, ERRORS.passwordMustContainUpperCaseLetter)
                .matches(/[a-z]/, ERRORS.passwordMustContainLowerCaseLetter)
                .matches(/\W/, ERRORS.passwordMustContainSpecialCharacter),
            passwordNewConfirmation: yup.string().when('passwordNew', {
                is: (value: string | undefined) => {
                    return value && value.length > 1;
                },
                then: (schema) =>
                    schema
                        .required(ERRORS.required)
                        .oneOf([yup.ref('passwordNew')], ERRORS.passwordsDoNotMatch),
                otherwise: (schema) => schema.trim(),
            }),
            passwordCurrent: yup.string().when('passwordNew', {
                is: (value: string | undefined) => {
                    return value && value.length > 0;
                },
                then: (schema) => schema.required(ERRORS.required),
            }),
        }),
    });

    useEffect(() => {
        if (open) {
            dispatch(getIPInfo());
            dispatch(getLayout());
        }
    }, [dispatch, open]);

    useEffect(() => {
        formik.resetForm();
    }, [resetFormikForm]);

    const validatePhoneNumber = (isValid: boolean) => {
        if (!isValid) {
            formik.setFieldError('phoneNumber', t('modals.settings.invalidNumber'));
        } else {
            formik.setFieldError('phoneNumber', undefined);
        }
    };

    useLayoutEffect(() => {
        if (!twoFactorAuthStatus) {
            formik.setFieldValue('phoneNumber', '', false);
            validatePhoneNumber(true);
        }
    }, [twoFactorAuthStatus]);

    useLayoutEffect(() => {
        if (IPInfo && IPInfo.country) {
            setIpCountryCode(IPInfo.country.toLowerCase());
        }
    }, [IPInfo]);

    useEffect(() => {
        formik.setValues({
            email: layout.user.mail,
            phoneNumber: layout.user.phoneNumber || '',
            passwordNew: '',
            passwordNewConfirmation: '',
            passwordCurrent: '',
        });
    }, [layout.user]);

    const getError = (inputName: keyof (typeof formik)['initialValues']) => {
        return formik.errors[inputName];
    };

    const inputProps: InputHTMLAttributes<HTMLInputElement> = {
        defaultValue: layout.user.mail,
        placeholder: 'email@email.com',
        onBlur: (v) => {
            formik.setFieldValue('email', v.target.value);
        },
    };
    return (
        <>
            <Modal disableOutsideClick overflowEnable template={2} open={open} onClose={onClose}>
                <>
                    {settingsModal2FA && settingsModal2FAPhoneNumber ? (
                        <SettingsModal2FA
                            setOpenParent={setOpen}
                            setState={setSettingsModal2FA}
                            state={settingsModal2FA}
                            phoneNumber={settingsModal2FAPhoneNumber}
                        />
                    ) : (
                        <></>
                    )}
                    {!settingsModal2FA ? (
                        <div className="settings">
                            <div className="settings-item">
                                <div className="settings-title">
                                    Settings
                                    <span
                                        onClick={() => setOpen(false)}
                                        className="settings-close-button cursor-pointer"
                                    >
                                        <CloseButton2Svg />
                                    </span>
                                </div>
                            </div>
                            <div className="settings-item email">
                                <div className="settings__em-title">
                                    {t('modals.settings.emailAddress')}
                                </div>
                                <div className="settings-item-description">
                                    {t('modals.settings.insertValidEmail')}{' '}
                                </div>
                                <InputText
                                    errorAsBlock
                                    template={2}
                                    fullWidth
                                    error={getError('email')}
                                    inputProps={inputProps}
                                    onChange={(v) => {
                                        formik.setFieldValue('email', v);
                                    }}
                                />
                            </div>
                            <div className="settings-item email">
                                <div className="settings__password-title">
                                    {t('modals.settings.language')}
                                </div>
                                <SelectMenu
                                    options={[
                                        {
                                            name: 'English',
                                            key: 'en',
                                        },
                                        {
                                            name: 'Portuguese',
                                            key: 'pt',
                                        },
                                        {
                                            name: 'Spanish',
                                            key: 'es',
                                        },
                                        {
                                            name: 'Chinese',
                                            key: 'cn',
                                        },
                                        {
                                            name: 'French',
                                            key: 'fr',
                                        },
                                    ].map((item) => {
                                        return {
                                            content: (
                                                <div title={item.name}>
                                                    <span>{item.name}</span>
                                                </div>
                                            ),
                                            onClick: () => {
                                                setSelectedLang(item.key);
                                                i18n.changeLanguage(item.key);
                                                localStorage.setItem(
                                                    'lang',
                                                    JSON.stringify(item.key),
                                                );
                                                API.put()(`/settings`, { lang: item.key }).then();

                                                moment.locale(item.key);
                                            },
                                        };
                                    })}
                                    text={languages.find((item) => item.key === selectedLang)?.name}
                                    wrapperClass="settings-language"
                                />
                            </div>

                            <div className="settings-hr" />
                            <div className="settings-item">
                                <div className="settings__password-title">
                                    {t('modals.settings.password')}
                                </div>
                                <div className="settings-item-description">
                                    {t('modals.settings.enterNewPassword')}{' '}
                                </div>
                                <div className="settings-password-repeat-parent">
                                    <InputPassword
                                        disableDebounce
                                        value={formik.values.passwordNew}
                                        errorAsBlock
                                        error={getError('passwordNew')}
                                        inputProps={{ placeholder: '*********' }}
                                        template={2}
                                        fullWidth
                                        defaultValue=""
                                        onChange={(v) => {
                                            formik.setFieldTouched('passwordNewConfirmation');
                                            formik.setFieldValue('passwordNew', v);
                                        }}
                                    />
                                    <InputPassword
                                        disableDebounce
                                        value={formik.values.passwordNewConfirmation}
                                        errorAsBlock
                                        error={getError('passwordNewConfirmation')}
                                        inputProps={{ placeholder: '*********' }}
                                        template={2}
                                        fullWidth
                                        defaultValue=""
                                        onChange={(v) => {
                                            formik.setFieldValue('passwordNewConfirmation', v);
                                        }}
                                    />
                                </div>
                                <div className="settings-item-description">
                                    {t('modals.settings.enterCurrentPassword')}{' '}
                                </div>
                                <InputPassword
                                    disableDebounce
                                    value={formik.values.passwordCurrent}
                                    errorAsBlock
                                    error={getError('passwordCurrent')}
                                    inputProps={{ placeholder: '*********' }}
                                    template={2}
                                    fullWidth
                                    defaultValue=""
                                    onChange={(v) => formik.setFieldValue('passwordCurrent', v)}
                                />
                                <div
                                    className="settings-forgot-password"
                                    onClick={() => {
                                        setOpenResetPassword(true);
                                    }}
                                >
                                    {t('modals.settings.forgotPassword')}
                                </div>
                            </div>
                            <div className="settings-hr" />
                            <div className="settings-item">
                                <div className="settings-2fa">
                                    <div className="settings__title">
                                        {t('modals.settings.2fa')}
                                    </div>
                                    <span
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setTwoFactorAuthStatus(!twoFactorAuthStatus);
                                        }}
                                    >
                                        {twoFactorAuthStatus ? (
                                            <SwitchIosOnSvg />
                                        ) : (
                                            <SwitchIosOffSvg />
                                        )}
                                    </span>
                                </div>
                                {twoFactorAuthStatus ? (
                                    <>
                                        <div className="settings-2fa-description">
                                            {t('modals.settings.phoneNumber')}
                                        </div>
                                        <InputPhone
                                            errorAsBlock
                                            defaultValue={formik.values.phoneNumber as string}
                                            fullWidth
                                            template={2}
                                            error={formik.errors.phoneNumber}
                                            ipCountryCode={
                                                layout.user.phoneNumber ? '' : ipCountryCode
                                            }
                                            onChange={(isValid, _inputValue, fullNumber) => {
                                                formik.setFieldValue(
                                                    'phoneNumber',
                                                    fullNumber,
                                                    false,
                                                );
                                                if (phoneNumberBlur) {
                                                    validatePhoneNumber(isValid);
                                                }
                                            }}
                                            onPhoneNumberBlur={(isValid) => {
                                                setPhoneNumberBlur(true);
                                                validatePhoneNumber(isValid);
                                            }}
                                            onSelectFlag={(isValid, _inputValue, fullNumber) => {
                                                if (formik.values.phoneNumber) {
                                                    formik.setFieldValue('phoneNumber', fullNumber);
                                                    setPhoneNumberBlur(true);
                                                    validatePhoneNumber(isValid);
                                                }
                                            }}
                                        />
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <SettingsModalValidationError />
                            <Button
                                disabled={!formik.isValid}
                                className="settings-submit"
                                onClick={() => formik.submitForm()}
                                type="secondary"
                            >
                                {t('general.submit')}
                            </Button>
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            </Modal>
            <SuccessModal
                lottie
                open={openSuccessModal}
                text={t('modals.settings.successfullyUpdated')}
                onClose={() => {
                    setOpenSuccessModal(false);
                }}
            />
            <ConfirmModal
                open={openResetPassword}
                text={t('modals.settings.areYouSureReset', { user: getNoEmpty(layout.user.name) })}
                success={successResetPassword}
                onClick={() => {
                    dispatch(
                        resetUserPassword({
                            email: layout.user.mail,
                            cb: () => {
                                setSuccessResetPassword(
                                    t('modals.settings.passResetSuccess', {
                                        user: getNoEmpty(layout.user.name),
                                    }),
                                );
                            },
                        }),
                    );
                }}
                onClose={() => {
                    setSuccessResetPassword(null);
                    setOpenResetPassword(null);
                }}
            />
        </>
    );
};

export default SettingsModal;
