import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Search from 'src/components/Search';
import Arrow from 'src/components/Arrow';
import SelectMenu from 'src/components/SelectMenu';
import { getElearning, putElearning } from 'src/redux/weeklyPlan/actions';
import NoResults from 'src/components/NoResults';
import { selectAimsMaterials } from 'src/redux/coursePlanner/selectors';
import { getAimsMaterials } from 'src/redux/coursePlanner/actions';
import { selectElernings } from 'src/redux/weeklyPlan/selectors';
import { selectLoading } from 'src/redux/loading/selectors';
import ArrowThin from 'src/components/Icons/ArrowThin';
import ELearningInfoData from 'src/components/ELearningInfoData';
import { ElearningProps } from 'src/pages/Homework/ELearning/types';
import { Modules, useIsModuleActive } from 'src/hooks/useIsModuleActive';
import NewAssignmentsELearningActivities from './NewAssignmentsELearningActivities';
import NewAssignmentsELearningInfo from './NewAssignmentsELearningInfo';
import { useNewAssignmentsFilterHelper } from './newAssignmentsFilterHelper';
import { newAssignmentsGetSearchResult } from './NewAssignmentsGetSearchResult';

import './styles.scss';

export const NEW_ASSIGNMENTS_LEVEL_DEFAULT_NAME = 'Level';

type Level = {
    name: string;
    id: null | string;
};

const NewAssignmentsELearningInfoBlock = ({ setUpdateLesson, updateLesson }: ElearningProps) => {
    const [num, setNum] = useState(0);
    const eLearning = useSelector(selectElernings);

    return (
        <div>
            <NewAssignmentsELearningInfo num={num} eLearning={eLearning} setNum={setNum} />

            <div className="NewAssignmentsEL-data">
                <ELearningInfoData
                    taskTime={eLearning[num].time}
                    taskLevel={eLearning[num].level}
                    taskPreviewUrl={`${eLearning[num].preview}`}
                    taskOup={eLearning[num].oup}
                />
                <NewAssignmentsELearningActivities
                    eLearning={eLearning}
                    num={num}
                    setUpdateLesson={(data) => setUpdateLesson(data)}
                    updateLesson={updateLesson}
                />
            </div>
        </div>
    );
};

const NewAssignmentsELearning = ({ setUpdateLesson, updateLesson }: ElearningProps) => {
    const loading = useSelector(selectLoading);
    const eLearning = useSelector(selectElernings);
    const [searchLevels, setSearchLevels] = useState<Level[]>([]);
    const [filters, setFilters] = useState({} as any);
    const [titles, setTitles] = useState({} as any);
    const [searchParams] = useSearchParams();
    const { updateFilter, updateTitle, deleteFilter } = useNewAssignmentsFilterHelper({
        filters,
        setFilters,
        titles,
        setTitles,
    });
    const aimsMaterials = useSelector(selectAimsMaterials);
    const [activeSearchResult, setActiveSearchResult] = useState<string | null>(null);
    const dispatch = useDispatch();
    const [category, setCategory] = useState('');
    const [area, setArea] = useState<string | null>(null);
    const [level, setLevel] = useState<string | null>(null);
    const { t } = useTranslation();
    const params = useParams();
    const { isModuleActive } = useIsModuleActive();

    const searchResults = useMemo(() => {
        return newAssignmentsGetSearchResult({
            categories: aimsMaterials,
            level: filters.level,
            searchText: filters.search,
        });
    }, [aimsMaterials, filters.level, filters.search]);

    const searchEmpty = !searchResults?.length && Object.values(filters)?.length;
    const searchNotEmpty = searchResults?.length && Object.values(filters)?.length;
    const noResults = !!searchEmpty && !loading;

    const getSearchLevels = () => {
        const levels = new Set<string>();
        aimsMaterials.categories.forEach((item) => {
            item.subCategories.forEach((subC) => {
                subC.levels.forEach((lev) => {
                    levels.add(lev.name);
                });
            });
        });
        return [
            { name: NEW_ASSIGNMENTS_LEVEL_DEFAULT_NAME, id: null },
            ...Array.from(levels)
                .map((value) => ({ name: value, id: value }))
                .sort((prev, next) => {
                    if (prev.name < next.name) {
                        return -1;
                    }
                    if (prev.name > next.name) {
                        return 1;
                    }
                    return 0;
                }),
        ];
    };

    const materials = useMemo(() => {
        const selectedCategory = aimsMaterials.categories.find((categoryForFind) => {
            return categoryForFind.name === category;
        });
        const selectedSubCategory = selectedCategory?.subCategories.find((currentSubCategory) => {
            return currentSubCategory.name === area;
        });

        return {
            selectedCategory,
            selectedSubCategory,
        };
    }, [aimsMaterials.categories, category, area]);

    useEffect(() => {
        setSearchLevels(getSearchLevels());
    }, [aimsMaterials]);

    useEffect(() => {
        dispatch(getAimsMaterials({ classId: params.id }));
    }, [dispatch]);

    return (
        <div
            className={classNames('NewAssignmentsEL-primary-parent', {
                'search-empty': searchEmpty,
                'search-not-empty': searchNotEmpty,
            })}
        >
            {isModuleActive(Modules.AddAssignmentsSearchAssignments) && (
                <div className="NewAssignmentsEL-search__inputs">
                    <div className="NewAssignmentsEL-search__level">
                        <SelectMenu
                            alwaysWide
                            wrapperClass="type_3"
                            options={searchLevels.map((searchLevel: any) => {
                                return {
                                    content: (
                                        <div title={searchLevel.name}>
                                            <span>{searchLevel.name}</span>
                                        </div>
                                    ),
                                    onClick: () => {
                                        if (searchLevel.id === null) {
                                            deleteFilter(['level']);
                                            updateTitle({ level: searchLevel.name });
                                        } else {
                                            updateFilter({ level: searchLevel.id });
                                            updateTitle({ level: searchLevel.name });
                                        }
                                        dispatch(putElearning({}));
                                        setActiveSearchResult('');
                                        setArea(null);
                                        setLevel(null);
                                        setCategory('');
                                    },
                                };
                            })}
                            text={titles.level ?? NEW_ASSIGNMENTS_LEVEL_DEFAULT_NAME}
                        />
                    </div>
                    <div className="NewAssignmentsEL-search__text-search">
                        <Search
                            key={JSON.stringify(aimsMaterials)}
                            defaultValue={(searchParams.get('searchTerm') || '').replace(/_/g, ' ')}
                            onPushEnter={() => {
                                setCategory('');
                                setArea(null);
                                setLevel(null);
                                setActiveSearchResult('');
                                dispatch(putElearning({}));
                            }}
                            fullWidth
                            onClearButton={() => {
                                setCategory('');
                                setArea(null);
                                setLevel(null);
                                dispatch(putElearning({}));
                                deleteFilter(['search', 'level']);
                                updateTitle({ level: searchLevels[0]?.name });
                            }}
                            alwaysWide
                            onChange={(text: string) => {
                                setCategory('');
                                setArea(null);
                                setLevel(null);
                                setActiveSearchResult('');
                                dispatch(putElearning({}));
                                if (text === '') {
                                    deleteFilter(['search']);
                                } else {
                                    updateFilter({ search: text });
                                }
                            }}
                        />
                    </div>
                </div>
            )}
            {noResults && (
                <NoResults
                    title={t('NewAssigment.noResultsFound')}
                    subtitle={t('NewAssigment.tryDifferentSearchTermOrClear')}
                />
            )}
            {!noResults &&
                (searchResults && Object.values(filters).length ? (
                    <div className="NewAssignmentsEL-search-results">
                        {searchResults?.map((searchResult) => {
                            return (
                                <div
                                    key={`${searchResult.name}_${searchResult.subName}_${searchResult.level}`}
                                    className={classNames(
                                        'NewAssignmentsEL-search-results-item cursor-pointer',
                                        {
                                            active:
                                                `${searchResult.name}_${searchResult.subName}_${searchResult.level}` ===
                                                activeSearchResult,
                                        },
                                    )}
                                    onClick={() => {
                                        setActiveSearchResult(
                                            `${searchResult.name}_${searchResult.subName}_${searchResult.level}`,
                                        );
                                        dispatch(
                                            getElearning({
                                                classId: params.id,
                                                id: searchResult.aims,
                                                type: 'aims',
                                            }),
                                        );
                                    }}
                                >
                                    <div className="NewAssignmentsEL-search-results-items__desc">
                                        <span>{searchResult.name}</span> -{' '}
                                        <span>{searchResult.level}</span>
                                    </div>
                                    <div className="NewAssignmentsEL-search-results-items__sub-category">
                                        {searchResult.subName}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className="NewAssignmentsEL-categories" id="NewAssignmentsELCategories">
                        <div className="NewAssignmentsEL-category category">
                            <div className="NewAssignmentsEL-category__title">
                                <span>{t('NewAssigment.choose')}</span>
                                <h2>{t('NewAssigment.category')}</h2>
                            </div>
                            <div className="NewAssignmentsEL-category__items">
                                {aimsMaterials.categories.map(
                                    (currentCategory, currentCategoryIndex) => {
                                        return (
                                            <span
                                                key={`${currentCategory.name}${currentCategoryIndex}`}
                                                className={classNames(
                                                    'NewAssignmentsEL-category__item',
                                                    {
                                                        active: category === currentCategory.name,
                                                    },
                                                )}
                                                onClick={() => {
                                                    dispatch(putElearning({}));
                                                    setCategory(currentCategory.name);
                                                    setArea(null);
                                                    setLevel(null);
                                                    const el = document.getElementById(
                                                        'NewAssignmentsELCategories',
                                                    );

                                                    el!.scrollLeft += 100;
                                                }}
                                            >
                                                {currentCategory.name}
                                                <div className="NewAssignmentsEL-category-item-arrow">
                                                    <ArrowThin />
                                                </div>
                                            </span>
                                        );
                                    },
                                )}
                            </div>
                        </div>

                        <div className="NewAssignmentsEL-category area">
                            <div className="NewAssignmentsEL-category__title">
                                <Arrow
                                    onClick={() => {
                                        dispatch(putElearning({}));
                                    }}
                                    direction="left"
                                />
                                <span>{t('NewAssigment.choose')}</span>
                                <h2>{t('NewAssigment.area')}</h2>
                            </div>
                            <div className="NewAssignmentsEL-category__items">
                                {materials.selectedCategory?.subCategories.map(
                                    (currentArea, areaIndex) => {
                                        return (
                                            <span
                                                key={`${currentArea.name}${areaIndex}`}
                                                className={classNames(
                                                    'NewAssignmentsEL-category__item',
                                                    {
                                                        active: currentArea.name === area,
                                                    },
                                                )}
                                                onClick={() => {
                                                    dispatch(putElearning({}));
                                                    setArea(currentArea.name);
                                                    setLevel(null);
                                                    const el = document.getElementById(
                                                        'NewAssignmentsELCategories',
                                                    );

                                                    el!.scrollLeft += 100;
                                                }}
                                            >
                                                {currentArea.name}
                                                <div className="NewAssignmentsEL-category-item-arrow">
                                                    <ArrowThin />
                                                </div>
                                            </span>
                                        );
                                    },
                                )}
                            </div>
                        </div>

                        <div className="NewAssignmentsEL-category level">
                            <div className="NewAssignmentsEL-category__title">
                                <Arrow
                                    onClick={() => {
                                        dispatch(putElearning({}));
                                    }}
                                    direction="left"
                                />
                                <div>
                                    <span>{t('NewAssigment.choose')}</span>
                                    <h2>{t('NewAssigment.group')}</h2>
                                </div>
                            </div>

                            <div className="NewAssignmentsEL-category__items">
                                {materials?.selectedSubCategory?.levels.map(
                                    (currentLevel, currentLevelIndex) => {
                                        return (
                                            <div
                                                key={`${currentLevel.name}${currentLevelIndex}`}
                                                className={classNames(
                                                    'NewAssignmentsEL-category__item',
                                                    {
                                                        active: currentLevel.name === level,
                                                    },
                                                )}
                                                onClick={() => {
                                                    setLevel(currentLevel.name);
                                                    dispatch(
                                                        getElearning({
                                                            classId: params.id,
                                                            id: currentLevel.aims,
                                                            type: 'aims',
                                                        }),
                                                    );
                                                }}
                                            >
                                                {currentLevel.name}
                                                <div className="NewAssignmentsEL-category-item-arrow">
                                                    <ArrowThin />
                                                </div>
                                            </div>
                                        );
                                    },
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            {!noResults && !!eLearning.length && (
                <NewAssignmentsELearningInfoBlock
                    setUpdateLesson={(data) => setUpdateLesson(data)}
                    updateLesson={updateLesson}
                />
            )}
            {!noResults && !eLearning.length && (level || activeSearchResult) && !loading && (
                <div className="NewAssigment-noResults">
                    <NoResults title={t('NewAssigment.noResults')} />
                </div>
            )}
        </div>
    );
};

export default NewAssignmentsELearning;
