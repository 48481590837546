export const defaultInputValue = (dataToSave: any, currentCategory: any, student: any) => {
    const dataToSaveItem = dataToSave.find(
        (data: any) => data.label === currentCategory.id && data.uid === student.uid,
    );
    const studentLabel = student.labels.find(({ id }: any) => id === currentCategory.id);

    /* this looks bad but fix issue for backend
        null = is remove 'value' from DB
        0 = value
        undefined = row not saved to db
     */
    if (dataToSaveItem) {
        return dataToSaveItem.value === null || dataToSaveItem.value === false
            ? ''
            : dataToSaveItem.value;
    }
    if (studentLabel) {
        return studentLabel.value === null || studentLabel.value === false
            ? ''
            : studentLabel.value;
    }
    return '';
};
