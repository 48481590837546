import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectModule } from 'src/redux/layout/selectors';

export enum Modules {
    Classes = 'classes',
    Students = 'students',
    Reports = 'reports',
    Emails = 'emails',
    Todo = 'todo',
    Information = 'information',
    QRCodes = 'qr_codes',
    Settings = 'settings',

    ClassWeeklyPlan = 'class_weekly_plan',
    ClassReports = 'class_reports',
    ClassStudents = 'class_students',
    ClassAssessments = 'class_assessments',
    ClassTests = 'class_tests',
    ClassAttendance = 'class_attendance',

    StudentProfileDetails = 'student_profile_details',
    StudentProfileHistory = 'student_profile_history',
    StudentProfileAuditLog = 'student_profile_audit_log',
    StudentProfileEmails = 'student_profile_emails',
    StudentProfileTutorials = 'student_profile_tutorials',
    StudentProfileTests = 'student_profile_tests',
    StudentProfileAssignments = 'student_profile_assignments',
    AddAssignmentsSearchAssignments = 'add_assignments_search_assignments',
    AddAssignmentsElearning = 'add_assignments_elearning',
    AddAssignmentsFlashpoint = 'add_assignments_flashpoint',
    AddAssignmentsRecommendations = 'add_assignments_recommendations',
    AddAssignmentsMyAssignments = 'add_assignments_my_assignments',

    StudentProfileCredits = 'student_profile_credits',
    StudentProfileCreditsDelete = 'student_profile_credits_delete',
    StudentProfilePackages = 'student_profile_packages',
    StudentProfileMarking = 'student_profile_marking',

    InviteStudent = 'invite_student',
    InviteStudentQr = 'invite_student_qr',
    StudentsUnmatched = 'students_unmatched',
    StudentsDuplicate = 'students_duplicate',
    WeeklyPlannerApplyTemplate = 'weekly_planner_apply_template',
    WeeklyPlannerApplyTemplateCurated = 'weekly_planner_apply_template_curated',
    WeeklyPlannerSearch = 'weekly_planner_search',
    WeeklyPlannerExport = 'weekly_planner_export',
    WeeklyPlannerViewAsStudent = 'weekly_planner_view_as_student',
    WeeklyPlannerTests = 'weekly_planner_tests',
    WeeklyPlannerTeacherNotes = 'weekly_planner_teacher_notes',

    StudentProfileMarkingLink = 'student_profile_marking_link',
    ClassReportsSummary = 'class_reports_summary',
    InviteTeacher = 'invite_teacher',
    StudentProfileFixedEndAccess = 'student_profile_fixed_end_access',
}

const modulesDisabledByDefault = [Modules.StudentProfileFixedEndAccess, Modules.ClassAttendance];

export const useIsModuleActive = () => {
    const modules = useSelector(selectModule, (oldValue, newValue) => {
        return JSON.stringify(oldValue) === JSON.stringify(newValue);
    });

    const isModuleActive = useCallback(
        (name: Modules) => {
            if (!modules?.length) {
                return !modulesDisabledByDefault.includes(name);
            }

            const activeModule = modules.find(({ module }) => module === name);

            if (!activeModule) {
                return !modulesDisabledByDefault.includes(name);
            }

            return activeModule.enabled;
        },
        [modules],
    );

    const getModuleName = useCallback(
        (name: Modules) => {
            if (!modules?.length) {
                return '';
            }

            const activeModule = modules.find(({ module }) => module === name);

            if (!activeModule) {
                return '';
            }

            return activeModule.name;
        },
        [modules],
    );

    return { isModuleActive, getModuleName };
};
