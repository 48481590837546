import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import WhiteBox from 'src/components/WhiteBox';
import { selectUnits, selectWeeklyPlan } from 'src/redux/weeklyPlan/selectors';
import { useCallbackPrompt } from 'src/hooks/useCallbackPrompt';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import { useTranslation } from 'react-i18next';
import NewAssignmentsELearning from 'src/pages/NewAssigment/NewAssignmentsELearning';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { putElearning } from 'src/redux/weeklyPlan/actions';
import ArrowThin from 'src/components/Icons/ArrowThin';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { API } from 'src/api';
import { selectLayout, selectModule } from 'src/redux/layout/selectors';
import { Modules, useIsModuleActive } from 'src/hooks/useIsModuleActive';
import MyAssignments from '../MyAssigments';
import HomeworkNotes from '../HomeworkNotes';
import { homeworkAddResourcesFromState } from '../helpers';
import Recommendations from '../Recommendations';
import Flashpoint from '../Flashpoint';
import LiveClass from '../LiveClass';

import './styles.scss';

const TABS = [
    Modules.AddAssignmentsElearning,
    Modules.AddAssignmentsFlashpoint,
    Modules.AddAssignmentsRecommendations,
    Modules.AddAssignmentsMyAssignments,
];

const HomeworkData = () => {
    const params = useParams();
    const { lessons, days } = useSelector(selectWeeklyPlan) as any;
    const { t } = useTranslation();
    const state = useLocation().state as any;
    const { isDesktop } = useWindowSize();
    const dispatch = useDispatch();
    const location = useLocation();
    const isStudent = location.pathname.includes('students');
    const { layout } = useSelector(selectLayout);
    const isIelts = layout?.isIelts;
    const { isModuleActive, getModuleName } = useIsModuleActive();
    const modules = useSelector(selectModule, (oldValue, newValue) => {
        return JSON.stringify(oldValue) === JSON.stringify(newValue);
    });

    const units = useSelector(selectUnits);
    const [tab, setTab] = useState(Modules.AddAssignmentsElearning);
    const [assignments, setAssignments] = useState(
        [] as unknown as {
            score: string;
            date: number;
            type: string;
            title: string;
            preview: string;
            id: number;
            contextId?: number;
        }[],
    );
    const { columnId, lessonId } =
        lessons
            ?.find(({ day }: any) => day === +params.day!)
            ?.lessons.find(({ name }: any) => /homework/i.test(name.trim())) || {};
    const currentLesson = useMemo(() => {
        if (isStudent) {
            return {
                activities: assignments.map((assignment) => ({
                    ...assignment,
                    name: assignment.title,
                    id: assignment.id || assignment.contextId,
                    isOld: true,
                })),
                teacherText: '',
            };
        }

        return days
            ?.find(({ day }: any) => day === +params.day!)
            ?.lessons.find(
                ({ columnId: colId, lessonId: lesId }: any) =>
                    columnId === colId && lessonId === lesId,
            );
    }, [days, isStudent, assignments]);

    const [isDirty, setIsDirty] = useState(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isDirty) as any;
    const [showPromptModal, setShowPromptModal] = useState(false as any);
    const [activeExercise, setActiveExercise] = useState({} as any);

    const [updateLesson, setUpdateLesson] = useState(currentLesson);

    const [activeStep, setActiveStep] = useState(isDesktop ? 'add' : '');

    const allSyllabus = useMemo(() => {
        const currentDayLessons = days?.find(({ day }: any) => day === +params.day!)?.lessons;
        let temp: any = [];
        temp = currentDayLessons?.map((val: any) => {
            return val?.syllabus.map(({ id }: any) => id);
        });
        return temp?.flat();
    }, [currentLesson]);

    const currentUnits = useMemo(() => {
        return units
            .map(({ name, pages }: any) => {
                return {
                    name,
                    pages: pages.filter(({ id }: any) => allSyllabus?.includes(id)),
                };
            })
            .filter(({ pages }: any) => pages.length);
    }, [units, allSyllabus]);

    const tabs = [
        {
            title: getModuleName(Modules.AddAssignmentsElearning) || t('homework.eLearningTitle'),
            value: Modules.AddAssignmentsElearning,
        },
        {
            title:
                getModuleName(Modules.AddAssignmentsFlashpoint) || isIelts
                    ? t('homework.videoClasses')
                    : t('homework.flashpoint'),
            value: Modules.AddAssignmentsFlashpoint,
        },
        {
            title:
                getModuleName(Modules.AddAssignmentsRecommendations) ||
                t('homework.recommendations'),
            value: Modules.AddAssignmentsRecommendations,
        },
        {
            title:
                getModuleName(Modules.AddAssignmentsMyAssignments) ||
                t('homework.myAssignmentsTitle'),
            value: Modules.AddAssignmentsMyAssignments,
        },
    ];

    const filteredTabs = tabs.filter(({ value }) => {
        return isModuleActive(value);
    });

    useEffect(() => {
        if (state && state.updateLesson) {
            setIsDirty(true);
            setUpdateLesson(state.updateLesson);
        } else {
            setUpdateLesson((prev: any) => {
                const activities = [
                    ...[...(currentLesson?.activities || []), ...(prev?.activities || [])]
                        .reduce((cur, next) => {
                            cur.set(next.id, next);
                            return cur;
                        }, new Map())
                        .values(),
                ];
                return {
                    ...(prev || {}),
                    ...(currentLesson || {}),
                    activities,
                };
            });
        }

        homeworkAddResourcesFromState({ state, currentLesson, setUpdateLesson });
    }, [currentLesson?.activities, state]);

    useEffect(() => {
        if (state && state.tab) {
            setTab(state.tab);
        }
    }, [state]);

    useEffect(() => {
        setShowPromptModal(showPrompt);
    }, [showPrompt]);

    useEffect(() => {
        if (!isStudent) {
            return;
        }
        dispatch(startLoading());
        API.get()(`/staff/students/${params.uid}/assignments`)
            .then((data) => {
                setAssignments(data);
            })
            .finally(() => {
                dispatch(finishLoading());
            });
    }, [isStudent]);

    useEffect(() => {
        const currentIds = currentLesson?.activities.map(({ id }: { id: number }) => id) || [];
        const updatedIds = updateLesson?.activities.map(({ id }: { id: number }) => id) || [];
        const isNotUpdated =
            currentIds.every((id: number) => updatedIds.includes(id)) &&
            currentIds.length === updatedIds.length;
        setIsDirty(!isNotUpdated);
    }, [currentLesson, updateLesson]);

    useEffect(() => {
        dispatch(putElearning({}));
    }, [tab]);

    useEffect(() => {
        if (modules) {
            setTab(TABS.filter((item) => isModuleActive(item))[0]);
        }
    }, [modules]);

    useEffect(() => {
        const storedUpdateLessonCurrentlyAddedActivities = JSON.parse(
            sessionStorage.getItem('updateLessonCurrentlyAddedActivities') || '[]',
        );

        if (storedUpdateLessonCurrentlyAddedActivities.length) {
            setUpdateLesson((prev: any) => ({
                ...prev,
                activities: [
                    ...prev.activities,
                    ...storedUpdateLessonCurrentlyAddedActivities.filter(
                        (activity: any) =>
                            !prev.activities.map(({ id }: any) => id).includes(activity.id),
                    ),
                ],
            }));
        }
    }, []);

    const activeTab = () => {
        switch (tab) {
            case Modules.AddAssignmentsElearning:
                return (
                    <NewAssignmentsELearning
                        setUpdateLesson={(data) => {
                            setUpdateLesson(data);
                            setActiveStep('assign');
                        }}
                        updateLesson={updateLesson}
                    />
                );
            case Modules.AddAssignmentsFlashpoint:
                if (isIelts) {
                    return (
                        <LiveClass
                            setUpdateLesson={(data) => {
                                setUpdateLesson(data);
                                setActiveStep('assign');
                            }}
                            updateLesson={updateLesson}
                        />
                    );
                }
                return (
                    <Flashpoint
                        setUpdateLesson={(data) => {
                            setUpdateLesson(data);
                            setActiveStep('assign');
                        }}
                        updateLesson={updateLesson}
                    />
                );
            case Modules.AddAssignmentsRecommendations:
                return (
                    <>
                        <Recommendations
                            setUpdateLesson={(data) => {
                                setUpdateLesson(data);
                                setActiveStep('assign');
                            }}
                            updateLesson={updateLesson}
                            currentUnits={currentUnits}
                            activeExercise={activeExercise}
                            setActiveExercise={setActiveExercise}
                        />
                    </>
                );
            case Modules.AddAssignmentsMyAssignments:
                return (
                    <MyAssignments
                        setUpdateLesson={(data) => {
                            setUpdateLesson(data);
                            setActiveStep('assign');
                        }}
                        updateLesson={updateLesson}
                        confirmNavigation={confirmNavigation}
                    />
                );
            default:
                return <></>;
        }
    };

    return (
        <div className="homework">
            {(activeStep === 'add' || isDesktop) && (
                <div className="homework-step1">
                    <div className="homework-step1-head">
                        {activeExercise.id &&
                        !isDesktop &&
                        tab === Modules.AddAssignmentsRecommendations ? (
                            <div
                                className="homework-backButton pr_hover_color_text_svg_bg"
                                onClick={() => {
                                    setActiveExercise({});
                                }}
                            >
                                <ArrowThin />
                                <span>{t('homework.step1')}</span>
                            </div>
                        ) : (
                            <span className="homework-title">
                                {t('NewAssigment.addAssignment')}
                            </span>
                        )}
                        {!isDesktop && isDirty && (
                            <div
                                className="homework-backButton homework-backButton-save pr_hover_color_text_svg_bg"
                                onClick={() => {
                                    setActiveStep('assign');
                                }}
                            >
                                <span>{t('homework.finish')}</span>
                                <ArrowThin />
                            </div>
                        )}
                    </div>
                    <WhiteBox>
                        <div className="homework-tabBar">
                            <div className="homework-tabs">
                                {filteredTabs.map((item) => (
                                    <span
                                        key={item.value}
                                        className={classNames(
                                            'homework-tab sc_hover_color_text_svg',
                                            {
                                                'homework-tab--active': item.value === tab,
                                            },
                                        )}
                                        onClick={() => setTab(item.value)}
                                    >
                                        {item.title}
                                    </span>
                                ))}
                            </div>
                        </div>

                        {activeTab()}
                    </WhiteBox>
                </div>
            )}
            {(activeStep === 'assign' || isDesktop) && (
                <div className="homework-step3">
                    {!isDesktop && (
                        <div
                            className="homework-backButton pr_hover_color_text_svg_bg"
                            onClick={() => {
                                setActiveStep('add');
                            }}
                        >
                            <ArrowThin />
                            <span>{t('NewAssigment.addAssignment')}</span>
                        </div>
                    )}
                    <span className="homework-title homework-title-assingn">
                        {t('homework.assignedWork')}
                    </span>
                    <HomeworkNotes
                        currentLesson={updateLesson}
                        setUpdateLesson={(data) => setUpdateLesson(data)}
                        setIsDirty={setIsDirty}
                        assignments={assignments}
                    />
                </div>
            )}
            <ConfirmModal
                text={t('homework.unsavedChangesConfirmation')}
                onClick={() => {
                    confirmNavigation();
                }}
                onClose={() => {
                    setShowPromptModal(false);
                    cancelNavigation();
                }}
                open={showPromptModal}
            />
        </div>
    );
};

export default HomeworkData;
