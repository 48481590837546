/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import ActionText from 'src/components/ActionText';
import CustomLink from 'src/components/CustomLink';
import CustomLinkOverloaded from 'src/components/CustomLink/CustomLinkOverloaded';
import CommentIcon from 'src/components/Icons/CommentIcon';
import Delete from 'src/components/Icons/Delete';
import Envelope from 'src/components/Icons/Envelope';
import InviteStudentButtonModal from 'src/components/InviteStudentButtonModal';
import MobileControls from 'src/components/MobileControls';
import CommentSaveModal from 'src/components/Modals/CommentSaveModal';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import Search from 'src/components/Search';
import SortableHeader from 'src/components/SortableHeader';
import Table from 'src/components/Table';
import WhiteBox from 'src/components/WhiteBox';
import { appConstants } from 'src/constants';
import { useClass } from 'src/hooks/useClass';
import useSortable from 'src/hooks/useSortable';
import { selectClass } from 'src/redux/classes/selectors';
import { putSidebar } from 'src/redux/layout/actions';
import { selectLayout } from 'src/redux/layout/selectors';
import {
    deleteStudent,
    getStudent,
    getStudents,
    saveStudentComment,
} from 'src/redux/students/actions';
import { selectStudent, selectStudents } from 'src/redux/students/selectors';
import { StudentsData } from './types';

import './styles.scss';

export const getStudentSurveyUrl = (student?: any) => {
    return student && student.survey
        ? `${appConstants.server}/living/ereception/add/${student.survey.timestamp}/${student.uid}/${student.survey.type}`
        : `${appConstants.server}/living/ereception/add/0/0/NdsAnl`;
};

const Students = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const { t } = useTranslation();
    const studentsData = useSelector(selectStudents);
    const [search, setSearch] = useState('');
    const classData = useSelector(selectClass);
    const [studentToDelete, setStudentToDelete] = useState(0);
    const [uid, setUid] = useState(0);
    const selectStudentVal = useSelector(selectStudent);
    const navigate = useNavigate();
    const sortable = useSortable({ itemsToSort: studentsData });
    const { layout } = useSelector(selectLayout);

    useEffect(() => {
        if (uid) {
            dispatch(
                getStudent({
                    uid,
                    classId: params.id,
                }),
            );
        }
    }, [uid]);

    useMemo(() => {
        sortable.updateFilter('name', search);
    }, [search, studentsData]);

    const getCommentButtonTitle = (student: StudentsData, isMob = false) => {
        if (isMob) {
            return student.note ? t('students.view') : t('students.add');
        }
        return student.note ? t('students.viewComment') : t('students.addComment');
    };

    const isDateBold = (start: number, end: number) => {
        const weekSeconds = 60 * 60 * 24 * 7 * 1000;
        if (start) {
            return new Date().getTime() - start <= weekSeconds;
        }

        return end - new Date().getTime() <= weekSeconds;
    };

    useClass();

    useEffect(() => {
        dispatch(
            getStudents({
                classId: params.id,
            }),
        );
        dispatch(putSidebar('classSelected'));

        return () => {
            dispatch(putSidebar());
        };
    }, [dispatch, params]);

    const unmarkedStudents = useMemo(() => {
        return studentsData.filter((item) => item.unmarkedExitTest);
    }, [studentsData]);

    return (
        <>
            <WhiteBox>
                {unmarkedStudents.length ? (
                    <div
                        className="title"
                        style={{
                            height: 'unset',
                            marginBottom: 0,
                        }}
                    >
                        <Envelope />
                        <div className="title-text">
                            {unmarkedStudents.map(({ name }, key: number) => (
                                <div key={key}>
                                    <span>
                                        {name} {t('allStudents.completed')}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                <div className="students-header">
                    <Search alwaysWide onChange={(text: string) => setSearch(text)} />
                    <div className="students-info">
                        {classData?.level && (
                            <div className="students-info__item">
                                <b>{t('students.level')}</b>
                                <span>{classData?.level}</span>
                            </div>
                        )}
                        {classData?.room && (
                            <div className="students-info__item">
                                <b>{t('students.room')}</b>
                                <span>{classData?.room}</span>
                            </div>
                        )}
                        <InviteStudentButtonModal page="student" desc />
                    </div>
                </div>

                <MobileControls>
                    <Search alwaysWide onChange={(text: string) => setSearch(text)} />
                    <div className="students-info">
                        <div className="students-info-mobRow">
                            {classData?.level && (
                                <div>
                                    <b>{t('students.level')}</b>
                                    <span>{classData?.level}</span>
                                </div>
                            )}
                            {classData?.room && (
                                <div>
                                    <b>{t('students.room')}</b>
                                    <span>{classData?.room}</span>
                                </div>
                            )}
                        </div>
                        <InviteStudentButtonModal page="student" />
                    </div>
                </MobileControls>

                <div className="students-table">
                    <Table
                        arrowLeft
                        tableRowsClassName="students-table-rows"
                        header={
                            <>
                                {layout.isIelts && (
                                    <>
                                        <SortableHeader
                                            addClass="students-name-ielts"
                                            onClick={() =>
                                                sortable.updateSort('name', (item: any) =>
                                                    item?.name?.toLowerCase(),
                                                )
                                            }
                                            direction={sortable.getDirection('name')}
                                        >
                                            {t('students.name')}
                                        </SortableHeader>
                                        <SortableHeader
                                            addClass="students-date-ielts"
                                            direction={sortable.getDirection('start')}
                                            onClick={() => {
                                                sortable.updateSort(
                                                    'start',
                                                    (item: any) => item?.start,
                                                );
                                            }}
                                        >
                                            {t('students.startDate')}
                                        </SortableHeader>
                                        <SortableHeader
                                            addClass="students-date-ielts"
                                            direction={sortable.getDirection('end')}
                                            onClick={() => {
                                                sortable.updateSort(
                                                    'end',
                                                    (item: any) => item?.end,
                                                );
                                            }}
                                        >
                                            {t('students.endDate')}
                                        </SortableHeader>
                                        <SortableHeader
                                            addClass="students-score"
                                            direction={sortable.getDirection('overall')}
                                            onClick={() => {
                                                sortable.updateSort(
                                                    'overall',
                                                    (item: any) => item?.ielts.score.overall,
                                                );
                                            }}
                                        >
                                            {t('students.overallScore')}
                                        </SortableHeader>
                                        <SortableHeader
                                            addClass="students-reading"
                                            direction={sortable.getDirection('reading')}
                                            onClick={() => {
                                                sortable.updateSort(
                                                    'reading',
                                                    (item: any) => item?.ielts.score.reading,
                                                );
                                            }}
                                        >
                                            {t('students.reading')}
                                        </SortableHeader>
                                        <SortableHeader
                                            addClass="students-listening"
                                            direction={sortable.getDirection('listening')}
                                            onClick={() => {
                                                sortable.updateSort(
                                                    'listening',
                                                    (item: any) => item?.ielts.score.listening,
                                                );
                                            }}
                                        >
                                            {t('students.listening')}
                                        </SortableHeader>
                                        <SortableHeader
                                            addClass="students-speaking"
                                            direction={sortable.getDirection('speaking')}
                                            onClick={() => {
                                                sortable.updateSort(
                                                    'speaking',
                                                    (item: any) => item?.ielts.score.speaking,
                                                );
                                            }}
                                        >
                                            {t('students.speaking')}
                                        </SortableHeader>
                                        <SortableHeader
                                            addClass="students-writing"
                                            direction={sortable.getDirection('writing')}
                                            onClick={() => {
                                                sortable.updateSort(
                                                    'writing',
                                                    (item: any) => item?.ielts.score.writing,
                                                );
                                            }}
                                        >
                                            {t('students.writing')}
                                        </SortableHeader>
                                        <SortableHeader
                                            addClass="students-last"
                                            direction={sortable.getDirection('last')}
                                            onClick={() => {
                                                sortable.updateSort(
                                                    'last',
                                                    (item: any) => item?.accessed,
                                                );
                                            }}
                                        >
                                            {t('students.lastAccessedDate')}
                                        </SortableHeader>
                                        <SortableHeader
                                            addClass="students-credit"
                                            direction={sortable.getDirection('credit')}
                                            onClick={() => {
                                                sortable.updateSort(
                                                    'credit',
                                                    (item: any) => item?.ielts.credits.ai,
                                                );
                                            }}
                                        >
                                            {t('students.flexCheckCount')}
                                        </SortableHeader>
                                        <SortableHeader
                                            addClass="students-lesson"
                                            direction={sortable.getDirection('lesson')}
                                            onClick={() => {
                                                sortable.updateSort(
                                                    'lesson',
                                                    (item: any) => item?.ielts.credits.general,
                                                );
                                            }}
                                        >
                                            {t('students.flexLessonCount')}
                                        </SortableHeader>
                                        <SortableHeader
                                            addClass="students-note-ielts"
                                            direction={sortable.getDirection('note')}
                                            onClick={() => {
                                                sortable.updateSort(
                                                    'note',
                                                    (item: any) => item?.note,
                                                );
                                            }}
                                        >
                                            {t('students.notes')}
                                        </SortableHeader>
                                    </>
                                )}
                                {!layout.isIelts && (
                                    <>
                                        <SortableHeader
                                            addClass="students-name"
                                            onClick={() =>
                                                sortable.updateSort('name', (item: any) =>
                                                    item?.name?.toLowerCase(),
                                                )
                                            }
                                            direction={sortable.getDirection('name')}
                                        >
                                            {t('students.name')}
                                        </SortableHeader>
                                        <SortableHeader
                                            addClass="students-date"
                                            direction={sortable.getDirection('start')}
                                            onClick={() => {
                                                sortable.updateSort(
                                                    'start',
                                                    (item: any) => item?.start,
                                                );
                                            }}
                                        >
                                            {t('students.startDate')}
                                        </SortableHeader>
                                        <SortableHeader
                                            addClass="students-date"
                                            direction={sortable.getDirection('end')}
                                            onClick={() => {
                                                sortable.updateSort(
                                                    'end',
                                                    (item: any) => item?.end,
                                                );
                                            }}
                                        >
                                            {t('students.endDate')}
                                        </SortableHeader>
                                        <SortableHeader
                                            addClass="students-age"
                                            direction={sortable.getDirection('age')}
                                            onClick={() => {
                                                sortable.updateSort(
                                                    'age',
                                                    (item: any) => item?.age,
                                                );
                                            }}
                                        >
                                            {t('students.age')}
                                        </SortableHeader>
                                        <SortableHeader
                                            addClass="students-attendance"
                                            direction={sortable.getDirection('attendance')}
                                            onClick={() => {
                                                sortable.updateSort(
                                                    'attendance',
                                                    (item: any) => item?.start,
                                                );
                                            }}
                                        >
                                            {t('students.attendance')}
                                        </SortableHeader>
                                        <SortableHeader
                                            addClass="students-tutorial"
                                            direction={sortable.getDirection('tutorial')}
                                            onClick={() => {
                                                sortable.updateSort('tutorial', (item: any) => {
                                                    return item?.tutorial?.timestamp;
                                                });
                                            }}
                                        >
                                            {t('students.tutorial')}
                                        </SortableHeader>
                                        <SortableHeader
                                            addClass="students-testStatus"
                                            direction={sortable.getDirection('test')}
                                            onClick={() => {
                                                sortable.updateSort('test', (item: any) => {
                                                    return item?.test?.timestamp;
                                                });
                                            }}
                                        >
                                            {t('students.testStatus')}
                                        </SortableHeader>
                                        <SortableHeader
                                            addClass="students-survey"
                                            direction={sortable.getDirection('survey')}
                                            onClick={() => {
                                                sortable.updateSort('survey', (item: any) => {
                                                    return item?.survey?.timestamp;
                                                });
                                            }}
                                        >
                                            {t('students.survey')}
                                        </SortableHeader>
                                        <SortableHeader
                                            addClass="students-note"
                                            direction={sortable.getDirection('note')}
                                            onClick={() => {
                                                sortable.updateSort(
                                                    'note',
                                                    (item: any) => item?.note,
                                                );
                                            }}
                                        >
                                            {t('students.notes')}
                                        </SortableHeader>
                                    </>
                                )}
                            </>
                        }
                        rows={sortable.items.map((student: StudentsData) => (
                            <>
                                <CustomLinkOverloaded
                                    parentProps={{
                                        className: classNames('students-name'),
                                    }}
                                    linkProps={{
                                        to: `/students/${classData.id}/${student.uid}/profile`,
                                        className: classNames(
                                            'students-active tr_hover_color_text',
                                            {
                                                'students-name-ielts': layout.isIelts,
                                                'students-name': !layout.isIelts,
                                            },
                                        ),
                                    }}
                                >
                                    {student.name || '-'}
                                </CustomLinkOverloaded>
                                <span
                                    className={classNames({
                                        isBold: isDateBold(student.start * 1000, 0),
                                        'students-date-ielts': layout.isIelts,
                                        'students-date': !layout.isIelts,
                                    })}
                                >
                                    {moment(student.start * 1000)
                                        .utc()
                                        .format('DD MMM YYYY')}
                                </span>
                                <span
                                    className={classNames({
                                        isBold: isDateBold(0, student.end * 1000),
                                        'students-date-ielts': layout.isIelts,
                                        'students-date': !layout.isIelts,
                                    })}
                                >
                                    {moment(student.end * 1000)
                                        .utc()
                                        .format('DD MMM YYYY')}
                                </span>
                                {layout.isIelts && (
                                    <>
                                        <b className="students-score">
                                            {student.ielts.scores.overall || '-'}
                                        </b>
                                        <span className={classNames('students-reading')}>
                                            {student.ielts.scores.reading || '-'}
                                        </span>
                                        <span className={classNames('students-listening')}>
                                            {student.ielts.scores.listening || '-'}
                                        </span>
                                        <span
                                            className={classNames('students-speaking', {
                                                'students-active tr_hover_color_text':
                                                    student.ielts.marking.speaking,
                                            })}
                                            onClick={() => {
                                                const { speaking } = student.ielts.marking;
                                                if (!speaking) {
                                                    return;
                                                }

                                                const link = `${appConstants.angularIelts}/tracker/results/${speaking.id}/marking/${student.uid}/${speaking.attemptId}`;
                                                window.open(link, '_blank');
                                            }}
                                        >
                                            {student.ielts.scores.speaking || '-'}
                                        </span>
                                        <span
                                            className={classNames('students-writing', {
                                                'students-active tr_hover_color_text':
                                                    student.ielts.marking.writing,
                                            })}
                                            onClick={() => {
                                                const { writing } = student.ielts.marking;
                                                if (!writing) {
                                                    return;
                                                }

                                                const link = `${appConstants.angularIelts}/tracker/writing/practice/${writing.id}/marking/${student.uid}/${writing.attemptId}`;
                                                window.open(link, '_blank');
                                            }}
                                        >
                                            {student.ielts.scores.writing || '-'}
                                        </span>

                                        <span className={classNames('students-last')}>
                                            {student.accessed
                                                ? moment
                                                      .unix(student.accessed)
                                                      .format('DD MMM YYYY')
                                                : t('students.none')}
                                        </span>
                                        <span className={classNames('students-credit')}>
                                            {student.ielts.credits.ai || '-'}
                                        </span>
                                        <span className={classNames('students-lesson')}>
                                            {student.ielts.credits.general || '-'}
                                        </span>
                                    </>
                                )}

                                {!layout.isIelts && (
                                    <>
                                        <span className="students-age">{student.age || '-'}</span>
                                        <span
                                            className={classNames('students-attendance', {
                                                isBold:
                                                    student.attendance < 80 ||
                                                    student.attendance === null,
                                            })}
                                        >
                                            {student.attendance === null
                                                ? t('students.na')
                                                : `${student.attendance}%`}
                                        </span>
                                        <CustomLinkOverloaded
                                            linkProps={{
                                                to: `${appConstants.server}/study/tutorials/add/${student.uid}`,
                                                className: classNames(
                                                    'students-tutorial students-active tr_hover_color_text',
                                                    {
                                                        isBold: student.tutorial.late,
                                                    },
                                                ),
                                            }}
                                            parentProps={{
                                                className: classNames('students-tutorial'),
                                            }}
                                        >
                                            {student.tutorial.text ||
                                                (student.tutorial.timestamp
                                                    ? moment
                                                          .unix(student.tutorial.timestamp)
                                                          .format('DD MMM YYYY')
                                                    : t('students.noneUppercase'))}
                                        </CustomLinkOverloaded>
                                        <span
                                            className={classNames('students-testStatus', {
                                                isBold: student.test.type === 'late',
                                                'students-active tr_hover_color_text':
                                                    !!student.test.url,
                                            })}
                                            onClick={() => {
                                                if (!student.test.url) {
                                                    return;
                                                }
                                                window.open(
                                                    student.test.url,
                                                    '_blank',
                                                    'noopener,noreferrer',
                                                );
                                            }}
                                        >
                                            {student.test.type === 'available' ? (
                                                t('students.available')
                                            ) : student.test.text === 'INVITED' ? (
                                                <span>
                                                    {student.test.text}
                                                    <br />
                                                    {student.test.timestamp &&
                                                        moment
                                                            .unix(student.test.timestamp)
                                                            .format('DD MMM YYYY')}
                                                </span>
                                            ) : (
                                                student.test.text ||
                                                (student.test.timestamp &&
                                                    moment
                                                        .unix(student.test.timestamp)
                                                        .format('DD MMM YYYY')) ||
                                                t('students.none')
                                            )}
                                        </span>
                                        <CustomLinkOverloaded
                                            linkProps={{
                                                to: getStudentSurveyUrl(student),
                                                className: 'tr_hover_color_text students-active',
                                            }}
                                            parentProps={{
                                                className: 'students-survey',
                                            }}
                                        >
                                            {(student.survey &&
                                                moment
                                                    .unix(student.survey.timestamp)
                                                    .format('DD MMM YYYY')) ||
                                                t('students.noneUppercase')}
                                        </CustomLinkOverloaded>
                                    </>
                                )}
                                <div
                                    className={classNames({
                                        'students-note': !layout.isIelts,
                                        'students-note-ielts': layout.isIelts,
                                    })}
                                >
                                    <div className="students-note-icon">
                                        {student.note && <CommentIcon fill="#0B80A4" />}
                                    </div>
                                    <span
                                        onClick={() => {
                                            setUid(student.uid);
                                        }}
                                        className="students-active students-comment-mob tr_hover_color_text_svg"
                                    >
                                        {getCommentButtonTitle(student, true)}
                                    </span>
                                    <span
                                        onClick={() => {
                                            setUid(student.uid);
                                        }}
                                        className="students-active students-comment tr_hover_color_text_svg"
                                    >
                                        {getCommentButtonTitle(student)}
                                    </span>
                                </div>
                            </>
                        ))}
                        headerMobile={
                            <>
                                <SortableHeader
                                    addClass=""
                                    direction={sortable.getDirection('name')}
                                    onClick={() =>
                                        sortable.updateSort('name', (item: any) =>
                                            item?.name?.toLowerCase(),
                                        )
                                    }
                                >
                                    <span>{t('students.student')}</span>
                                </SortableHeader>
                            </>
                        }
                        rowsMobile={sortable.items.map((student: StudentsData, i: number) => {
                            return {
                                head: (
                                    <div className="students-header-mob">
                                        <div className="students-name-mobile students-active">
                                            <b>{i + 1}.</b>
                                            {student.name}
                                        </div>
                                        <ActionText
                                            onClick={() =>
                                                navigate(
                                                    `/students/${params.id}/${student.uid}/profile`,
                                                )
                                            }
                                        >
                                            {t('students.viewUppercase')}
                                        </ActionText>
                                    </div>
                                ),
                                content: (
                                    <>
                                        <div className="students-mobile-row">
                                            <div className="students-mobile-row-title">
                                                <b>{t('students.startDate')}</b>
                                            </div>
                                            <span
                                                className={classNames({
                                                    isBold: isDateBold(student.start * 1000, 0),
                                                })}
                                            >
                                                {moment(student.start * 1000).format('DD MMM YYYY')}
                                            </span>
                                        </div>
                                        <div className="students-mobile-row">
                                            <div className="students-mobile-row-title">
                                                <b>{t('students.endDate')}</b>
                                            </div>

                                            <span
                                                className={classNames({
                                                    isBold: isDateBold(0, student.end * 1000),
                                                })}
                                            >
                                                {moment(student.end * 1000).format('DD MMM YYYY')}
                                            </span>
                                        </div>

                                        {layout.isIelts && (
                                            <>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('students.overallScore')}</b>
                                                    </div>
                                                    <b>{student.ielts.scores.overall}</b>
                                                </div>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('students.reading')}</b>
                                                    </div>
                                                    <span>
                                                        {student.ielts.scores.reading || '-'}
                                                    </span>
                                                </div>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('students.listening')}</b>
                                                    </div>
                                                    <span>
                                                        {student.ielts.scores.listening || '-'}
                                                    </span>
                                                </div>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('students.writing')}</b>
                                                    </div>
                                                    <span
                                                        className={classNames('students-writing', {
                                                            'students-active tr_hover_color_text':
                                                                student.ielts.marking.writing,
                                                        })}
                                                        onClick={() => {
                                                            const { writing } =
                                                                student.ielts.marking;
                                                            if (!writing) {
                                                                return;
                                                            }

                                                            const link = `${appConstants.angularIelts}/tracker/writing/practice/${writing.id}/marking/${student.uid}/${writing.attemptId}`;
                                                            window.open(link, '_blank');
                                                        }}
                                                    >
                                                        {student.ielts.scores.writing || '-'}
                                                    </span>
                                                </div>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('students.speaking')}</b>
                                                    </div>
                                                    <span
                                                        className={classNames('students-writing', {
                                                            'students-active tr_hover_color_text':
                                                                student.ielts.marking.speaking,
                                                        })}
                                                        onClick={() => {
                                                            const { speaking } =
                                                                student.ielts.marking;
                                                            if (!speaking) {
                                                                return;
                                                            }

                                                            const link = `${appConstants.angularIelts}/tracker/results/${speaking.id}/marking/${student.uid}/${speaking.attemptId}`;
                                                            window.open(link, '_blank');
                                                        }}
                                                    >
                                                        {student.ielts.scores.speaking || '-'}
                                                    </span>
                                                </div>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('students.lastAccessedDate')}</b>
                                                    </div>
                                                    <span>
                                                        {student.accessed
                                                            ? moment
                                                                  .unix(student.accessed)
                                                                  .format('DD MMM YYYY')
                                                            : t('students.none')}
                                                    </span>
                                                </div>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('students.flexCheckCount')}</b>
                                                    </div>
                                                    <span>{student.ielts.credits.ai || '-'}</span>
                                                </div>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('students.flexLessonCount')}</b>
                                                    </div>
                                                    <span>
                                                        {student.ielts.credits.general || '-'}
                                                    </span>
                                                </div>
                                            </>
                                        )}

                                        {!layout.isIelts && (
                                            <>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('students.age')}</b>
                                                    </div>
                                                    <span>{student.age}</span>
                                                </div>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('students.attendance')}</b>
                                                    </div>
                                                    <span
                                                        className={classNames({
                                                            isBold:
                                                                student.attendance < 80 ||
                                                                student.attendance === null,
                                                        })}
                                                    >
                                                        {student.attendance === null
                                                            ? 'N/A'
                                                            : `${student.attendance}%`}
                                                    </span>
                                                </div>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('students.tutorial')}</b>
                                                    </div>
                                                    <span
                                                        className={classNames('students-active', {
                                                            isBold: student.tutorial.late,
                                                        })}
                                                        onClick={() => {
                                                            window.open(
                                                                `${appConstants.server}/study/tutorials/add/${student.uid}`,
                                                                '_blank',
                                                                'noopener,noreferrer',
                                                            );
                                                        }}
                                                    >
                                                        {student.tutorial.text ||
                                                            (student.tutorial.timestamp
                                                                ? moment
                                                                      .unix(
                                                                          student.tutorial
                                                                              .timestamp,
                                                                      )
                                                                      .format('DD MMM YYYY')
                                                                : 'None')}
                                                    </span>
                                                </div>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('students.testStatus')}</b>
                                                    </div>
                                                    <span
                                                        className={classNames({
                                                            isBold: student.test.type === 'late',
                                                            'students-active tr_hover_color_text':
                                                                !!student.test.url,
                                                        })}
                                                        onClick={() => {
                                                            if (!student.test.url) {
                                                                return;
                                                            }
                                                            window.open(
                                                                student.test.url,
                                                                '_blank',
                                                                'noopener,noreferrer',
                                                            );
                                                        }}
                                                    >
                                                        {student.test.type === 'available' ? (
                                                            t('students.available')
                                                        ) : student.test.text === 'INVITED' ? (
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    gap: '0.5rem',
                                                                }}
                                                            >
                                                                <span>{student.test.text}</span>
                                                                <span>
                                                                    {student.test.timestamp &&
                                                                        moment
                                                                            .unix(
                                                                                student!.test!
                                                                                    .timestamp,
                                                                            )
                                                                            .format('DD MMM YYYY')}
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            student.test.text ||
                                                            (student.test.timestamp &&
                                                                moment
                                                                    .unix(student.test.timestamp)
                                                                    .format('DD MMM YYYY')) ||
                                                            t('students.none')
                                                        )}
                                                    </span>
                                                </div>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('students.survey')}</b>
                                                    </div>
                                                    <CustomLink
                                                        to={getStudentSurveyUrl(student)}
                                                        className="tr_hover_color_text students-active"
                                                    >
                                                        {(student.survey &&
                                                            moment
                                                                .unix(student.survey.timestamp)
                                                                .format('DD MMM YYYY')) ||
                                                            t('students.noneUppercase')}
                                                    </CustomLink>
                                                </div>
                                            </>
                                        )}
                                        <div className="students-mobile-row">
                                            <div className="students-mobile-row-title">
                                                <b>{t('students.note')}</b>
                                            </div>
                                            <span
                                                onClick={() => {
                                                    setUid(student.uid);
                                                }}
                                                className="students-active students-note-mobile"
                                            >
                                                {getCommentButtonTitle(student)}
                                            </span>
                                        </div>
                                        <div className="students-mobile-row">
                                            <div className="students-mobile-row-title" />
                                            <div
                                                onClick={() => {
                                                    setStudentToDelete(student.uid);
                                                }}
                                            >
                                                <Delete />
                                            </div>
                                        </div>
                                    </>
                                ),
                            };
                        })}
                    />
                </div>
            </WhiteBox>
            <CommentSaveModal
                open={uid}
                comment={selectStudentVal.note ?? ''}
                onSave={(textToSave: string) => {
                    dispatch(
                        saveStudentComment({
                            classId: params.id,
                            uid,
                            note: textToSave,
                        }),
                    );
                    setUid(0);
                }}
                onClose={() => {
                    setUid(0);
                }}
            />
            <ConfirmModal
                open={studentToDelete}
                text={t('students.deleteConfirm')}
                onClick={() => {
                    dispatch(
                        deleteStudent({
                            classId: params.id,
                            uid: studentToDelete,
                        }),
                    );
                    setStudentToDelete(0);
                }}
                onClose={() => {
                    setStudentToDelete(0);
                }}
            />
        </>
    );
};

export default Students;
