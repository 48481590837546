/* eslint-disable no-nested-ternary */
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import CustomLinkOverloaded from 'src/components/CustomLink/CustomLinkOverloaded';

import WhiteBox from 'src/components/WhiteBox';
import Table from 'src/components/Table';
import { getStudentSurveyUrl } from 'src/pages/Students';
import { selectAllStudents } from 'src/redux/allStudents/selectors';
import { getAllStudents, putAllStudents } from 'src/redux/allStudents/actions';
import CommentIcon from 'src/components/Icons/CommentIcon';
import { getStudent, saveStudentComment } from 'src/redux/students/actions';
import CommentSaveModal from 'src/components/Modals/CommentSaveModal';
import { selectStudent } from 'src/redux/students/selectors';
import { putBreadcrumbs, putSidebar } from 'src/redux/layout/actions';
import { useFilterHelper } from 'src/hooks/useFilterHelper';
import ActionText from 'src/components/ActionText';
import { allStudentsInitialState } from 'src/redux/allStudents/reducer';
import { appConstants } from 'src/constants';
import TablePaginationAPI from 'src/components/TablePaginationAPI';
import { usePaginationAPIProcessor } from 'src/hooks/usePaginationProcessorAPI';
import { selectLayout } from 'src/redux/layout/selectors';
import Checkbox from 'src/components/Checkbox';
import Envelope from 'src/components/Icons/Envelope';
import { StudentsData } from '../Students/types';
import { allStudentsAreInSchoolFilterItems, allStudentsSchoolFilterItems } from './constants';
import StudentsHeader from './StudentsHeader';
import AllStudentsFilter from './StudentsFilter';

import './styles.scss';

const AllStudents = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const allStudents = useSelector(selectAllStudents);
    const paginationProcessor = usePaginationAPIProcessor({
        items: allStudents,
        limit: allStudents.recordsPerPage,
    });
    const [getCommentUid, setGetCommentUid] = useState(0);
    const student = useSelector(selectStudent);
    const { layout } = useSelector(selectLayout);
    const [filter, setFilter] = useState({
        active: true,
        school: allStudentsSchoolFilterItems(t)[0].id,
        rangeFrom: moment().clone().startOf('isoWeek').unix(),
        rangeTo: moment().clone().endOf('isoWeek').unix(),
        rangeType: allStudentsAreInSchoolFilterItems(t)[0].id,
    } as any);
    const { updateFilter } = useFilterHelper({ filter, setFilter });
    const getCommentButtonTitle = (titleStudent: StudentsData, isMob = false) => {
        if (isMob) {
            return titleStudent.note
                ? t('general.view').toUpperCase()
                : t('general.add').toUpperCase();
        }
        return titleStudent.note
            ? t('general.viewComment').toUpperCase()
            : t('general.addComment').toUpperCase();
    };
    const [uid, setUid] = useState(0);
    const [selectedStudents, setSelectedStudents] = useState<number[]>([]);
    const isAllStudentsSelected = selectedStudents.length === paginationProcessor.data.length;

    const isDateBold = (start: number, end: number) => {
        const weekSeconds = 60 * 60 * 24 * 7 * 1000;
        if (start) {
            return new Date().getTime() - start <= weekSeconds;
        }

        return end - new Date().getTime() <= weekSeconds;
    };

    const toggleHeaderCheckboxClick = () => {
        if (selectedStudents.length > 0) {
            setSelectedStudents([]);
            return;
        }

        if (isAllStudentsSelected) {
            setSelectedStudents([]);
        } else {
            setSelectedStudents(paginationProcessor.data.map((item) => item.uid));
        }
    };

    const toggleStudentCheckboxClick = (id: number) => {
        if (selectedStudents.includes(id)) {
            setSelectedStudents(selectedStudents.filter((item) => item !== id));
        } else {
            setSelectedStudents([...selectedStudents, id]);
        }
    };

    useEffect(() => {
        dispatch(putSidebar('root'));
        return () => {
            dispatch(putSidebar());
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(
            putBreadcrumbs([
                {
                    name: t('general.students'),
                },
            ]),
        );

        return () => {
            dispatch(putAllStudents(allStudentsInitialState.allStudents));
        };
    }, []);

    useEffect(() => {
        if (getCommentUid > 0) {
            dispatch(getStudent({ uid: getCommentUid }));
        }
    }, [getCommentUid]);

    const unmarkedStudents = useMemo(() => {
        return allStudents.data.filter((item) => item.unmarkedExitTest);
    }, [allStudents]);

    return (
        <>
            <WhiteBox styles={{ height: '100%' }}>
                {unmarkedStudents.length ? (
                    <div
                        className="title"
                        style={{
                            height: 'unset',
                            marginBottom: 0,
                        }}
                    >
                        <Envelope />
                        <div className="title-text">
                            {unmarkedStudents.map(({ name }, key: number) => (
                                <div key={key}>
                                    <span>
                                        {name} {t('allStudents.completed')}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                <StudentsHeader
                    filter={filter}
                    setFilter={setFilter}
                    showActionButtons={!!selectedStudents.length}
                    selectedStudents={selectedStudents}
                    setSelectedStudents={setSelectedStudents}
                />
                <AllStudentsFilter
                    filter={filter}
                    setFilter={setFilter}
                    setOpenFilterModal={() => {}}
                />
                <div className="all-students-table">
                    <Table
                        customEmptyMessage={t('allStudents.noResults')}
                        tableRowsClassName="assessments-table-rows"
                        header={
                            <>
                                {layout.isIelts && (
                                    <>
                                        <Checkbox
                                            checked={selectedStudents.length > 0}
                                            type="bold"
                                            onClick={() => toggleHeaderCheckboxClick()}
                                            checkedIconType={
                                                isAllStudentsSelected ? 'checkmark' : 'minus'
                                            }
                                            styles={{
                                                wrapperBackground: 'rgb(var(--mainColor))',
                                                background: '#FFF',
                                                checkedBackground: 'transparent',
                                                borderColor: '#A3A3A3',
                                                checkedBorderColor: '#FFF',
                                            }}
                                        />
                                        <span className="students-name-ielts">
                                            {t('allStudents.nameTest')}
                                        </span>
                                        <span className="students-date-ielts">
                                            {t('general.startDate')}
                                        </span>
                                        <span className="students-date-ielts">
                                            {t('general.endDate')}
                                        </span>
                                        <span className="students-score">
                                            {t('general.overallScore')}
                                        </span>
                                        <span className="students-reading">
                                            {t('general.reading')}
                                        </span>
                                        <span className="students-listening">
                                            {t('general.listening')}
                                        </span>
                                        <span className="students-speaking">
                                            {t('general.speaking')}
                                        </span>
                                        <span className="students-writing">
                                            {t('general.writing')}
                                        </span>
                                        <span className="students-last">
                                            {t('allStudents.lastAccessedDate')}
                                        </span>
                                        <span className="students-credit">
                                            {t('allStudents.flexCheckCount')}
                                        </span>
                                        <span className="students-lesson">
                                            {t('allStudents.flexLessonCount')}
                                        </span>
                                        <span className="students-note-ielts">
                                            {t('general.notes')}
                                        </span>
                                    </>
                                )}
                                {!layout.isIelts && (
                                    <>
                                        <Checkbox
                                            checked={selectedStudents.length > 0}
                                            type="bold"
                                            onClick={() => toggleHeaderCheckboxClick()}
                                            checkedIconType={
                                                isAllStudentsSelected ? 'checkmark' : 'minus'
                                            }
                                            styles={{
                                                wrapperBackground: 'rgb(var(--mainColor))',
                                                background: '#FFF',
                                                checkedBackground: 'transparent',
                                                borderColor: '#A3A3A3',
                                                checkedBorderColor: '#FFF',
                                            }}
                                        />
                                        <span className="students-name">{t('general.name')}</span>
                                        <span className="students-date">
                                            {t('general.startDate')}
                                        </span>
                                        <span className="students-date">
                                            {t('general.endDate')}
                                        </span>
                                        <span className="students-age">{t('general.age')}</span>
                                        <span className="students-attendance">
                                            {t('general.attendance')}
                                        </span>
                                        <span className="students-tutorial">
                                            {t('general.tutorial')}
                                        </span>
                                        <span className="students-testStatus">
                                            {t('general.testStatus')}
                                        </span>
                                        <span className="students-survey">
                                            {t('general.survey')}
                                        </span>
                                        <span className="students-note">{t('general.note')}</span>
                                    </>
                                )}
                            </>
                        }
                        rows={paginationProcessor.data.map((oneStudent) => {
                            return (
                                <>
                                    <Checkbox
                                        checked={selectedStudents.includes(oneStudent.uid)}
                                        type="bold"
                                        onClick={() => toggleStudentCheckboxClick(oneStudent.uid)}
                                    />
                                    <CustomLinkOverloaded
                                        linkProps={{
                                            to: `/all-students/${oneStudent.uid}/profile`,
                                            state: { allStudents: true },
                                            className: classNames(
                                                'students-active tr_hover_color_text',
                                                {
                                                    'students-name-ielts': layout.isIelts,
                                                    'students-name': !layout.isIelts,
                                                },
                                            ),
                                        }}
                                        parentProps={{
                                            className: classNames(
                                                'students-active tr_hover_color_text',
                                                {
                                                    'students-name-ielts': layout.isIelts,
                                                    'students-name': !layout.isIelts,
                                                },
                                            ),
                                        }}
                                    >
                                        {oneStudent.name || '-'}
                                    </CustomLinkOverloaded>
                                    <span
                                        className={classNames({
                                            isBold: isDateBold(oneStudent.start * 1000, 0),
                                            'students-date-ielts': layout.isIelts,
                                            'students-date': !layout.isIelts,
                                        })}
                                    >
                                        {moment(oneStudent.start * 1000)
                                            .utc()
                                            .format('DD MMM YYYY')}
                                    </span>
                                    <span
                                        className={classNames({
                                            isBold: isDateBold(0, oneStudent.end * 1000),
                                            'students-date-ielts': layout.isIelts,
                                            'students-date': !layout.isIelts,
                                        })}
                                    >
                                        {moment(oneStudent.end * 1000)
                                            .utc()
                                            .format('DD MMM YYYY')}
                                    </span>
                                    {layout.isIelts && (
                                        <>
                                            <b className="students-score">
                                                {oneStudent.ielts.scores.overall || '-'}
                                            </b>
                                            <span className={classNames('students-reading')}>
                                                {oneStudent.ielts.scores.reading || '-'}
                                            </span>
                                            <span className={classNames('students-listening')}>
                                                {oneStudent.ielts.scores.listening || '-'}
                                            </span>
                                            <CustomLinkOverloaded
                                                parentProps={{
                                                    className: classNames('students-speaking', {
                                                        'students-active tr_hover_color_text':
                                                            oneStudent.ielts.marking.speaking,
                                                        disabled:
                                                            !oneStudent.ielts.marking.speaking,
                                                    }),
                                                }}
                                                linkProps={{
                                                    to: oneStudent.ielts.marking.speaking
                                                        ? `${appConstants.angularIelts}/tracker/results/${oneStudent.ielts.marking.speaking?.id}/marking/${oneStudent.uid}/${oneStudent.ielts.marking.speaking?.attemptId}`
                                                        : '',
                                                }}
                                            >
                                                {oneStudent.ielts.scores.speaking || '-'}
                                            </CustomLinkOverloaded>
                                            <CustomLinkOverloaded
                                                parentProps={{
                                                    className: classNames('students-writing', {
                                                        'students-active tr_hover_color_text':
                                                            oneStudent.ielts.marking.writing,
                                                        disabled: !oneStudent.ielts.marking.writing,
                                                    }),
                                                }}
                                                linkProps={{
                                                    to: oneStudent.ielts.marking.writing
                                                        ? `${appConstants.angularIelts}/tracker/writing/practice/${oneStudent.ielts.marking.writing?.id}/marking/${oneStudent.uid}/${oneStudent.ielts.marking.writing?.attemptId}`
                                                        : '',
                                                }}
                                            >
                                                {oneStudent.ielts.scores.writing || '-'}
                                            </CustomLinkOverloaded>

                                            <span className={classNames('students-last')}>
                                                {oneStudent.accessed
                                                    ? moment
                                                          .unix(oneStudent.accessed)
                                                          .format('DD MMM YYYY')
                                                    : t('general.none').toUpperCase()}
                                            </span>
                                            <span className={classNames('students-credit')}>
                                                {oneStudent.ielts.credits.ai || '-'}
                                            </span>
                                            <span className={classNames('students-lesson')}>
                                                {oneStudent.ielts.credits.general || '-'}
                                            </span>
                                        </>
                                    )}
                                    {!layout.isIelts && (
                                        <>
                                            <span className="students-age">
                                                {oneStudent.age || '-'}
                                            </span>
                                            <span
                                                className={classNames('students-attendance', {
                                                    isBold:
                                                        oneStudent.attendance < 80 ||
                                                        oneStudent.attendance === null,
                                                })}
                                            >
                                                {oneStudent.attendance === null
                                                    ? t('general.NA')
                                                    : `${oneStudent.attendance}%`}
                                            </span>
                                            <CustomLinkOverloaded
                                                linkProps={{
                                                    to: `${appConstants.server}/study/tutorials/add/${oneStudent.uid}`,
                                                }}
                                                parentProps={{
                                                    className: classNames(
                                                        'students-tutorial students-active tr_hover_color_text',
                                                        {
                                                            isBold: oneStudent.tutorial.late,
                                                        },
                                                    ),
                                                }}
                                            >
                                                {oneStudent.tutorial.text ||
                                                    (oneStudent.tutorial.timestamp
                                                        ? moment
                                                              .unix(oneStudent.tutorial.timestamp)
                                                              .format('DD MMM YYYY')
                                                        : t('general.none'))}
                                            </CustomLinkOverloaded>
                                            <CustomLinkOverloaded
                                                parentProps={{
                                                    className: classNames('students-testStatus', {
                                                        isBold: oneStudent.test.type === 'late',
                                                        'students-active tr_hover_color_text':
                                                            !!oneStudent.test.url,
                                                    }),
                                                }}
                                                linkProps={{
                                                    className: classNames({
                                                        disabled: !oneStudent.test.url,
                                                    }),
                                                    to: oneStudent.test.url,
                                                }}
                                            >
                                                {oneStudent.test.type === 'available' ? (
                                                    'AVAILABLE'
                                                ) : oneStudent.test.text === 'INVITED' ? (
                                                    <span>
                                                        {oneStudent.test.text}
                                                        <br />
                                                        {oneStudent.test.timestamp &&
                                                            moment
                                                                .unix(oneStudent.test.timestamp)
                                                                .format('DD MMM YYYY')}
                                                    </span>
                                                ) : (
                                                    oneStudent.test.text ||
                                                    (oneStudent.test.timestamp &&
                                                        moment
                                                            .unix(oneStudent.test.timestamp)
                                                            .format('DD MMM YYYY')) ||
                                                    t('general.none').toUpperCase()
                                                )}
                                            </CustomLinkOverloaded>
                                            <CustomLinkOverloaded
                                                parentProps={{
                                                    className:
                                                        'students-survey tr_hover_color_text students-active',
                                                }}
                                                linkProps={{
                                                    to: getStudentSurveyUrl(oneStudent),
                                                }}
                                            >
                                                {(oneStudent.survey &&
                                                    moment
                                                        .unix(oneStudent.survey.timestamp)
                                                        .format('DD MMM YYYY')) ||
                                                    t('general.none')}
                                            </CustomLinkOverloaded>
                                        </>
                                    )}
                                    <div
                                        className={classNames({
                                            'students-note': !layout.isIelts,
                                            'students-note-ielts': layout.isIelts,
                                        })}
                                    >
                                        <div className="students-note-icon">
                                            {oneStudent.note && <CommentIcon fill="#0B80A4" />}
                                        </div>
                                        <span
                                            onClick={() => {
                                                setUid(oneStudent.uid);
                                                setGetCommentUid(oneStudent.uid);
                                            }}
                                            className="students-active students-comment-mob tr_hover_color_text_svg"
                                        >
                                            {getCommentButtonTitle(oneStudent, true)}
                                        </span>
                                        <span
                                            onClick={() => {
                                                setUid(oneStudent.uid);
                                                setGetCommentUid(oneStudent.uid);
                                            }}
                                            className="students-active students-comment tr_hover_color_text_svg"
                                        >
                                            {getCommentButtonTitle(oneStudent)}
                                        </span>
                                    </div>
                                </>
                            );
                        })}
                        headerMobile={
                            <>
                                <span>{t('general.student')}</span>
                            </>
                        }
                        arrowLeft
                        rowsMobile={allStudents.data.map((oneStudentMob, idx) => {
                            return {
                                head: (
                                    <div className="all-students-mobile-buttons">
                                        <div className="students-name-mobile students-active">
                                            <b>
                                                {paginationProcessor.currentPageStartIndex +
                                                    idx +
                                                    1}
                                                .
                                            </b>
                                            {oneStudentMob.name}
                                        </div>
                                        <ActionText
                                            onClick={() => {
                                                navigate(
                                                    `/all-students/${oneStudentMob.uid}/profile`,
                                                    {
                                                        state: { allStudents: true },
                                                    },
                                                );
                                            }}
                                        >
                                            View
                                        </ActionText>
                                    </div>
                                ),
                                content: (
                                    <>
                                        <div className="students-mobile-row">
                                            <div className="students-mobile-row-title">
                                                <b>{t('general.startDate')}</b>
                                            </div>
                                            <span
                                                className={classNames({
                                                    isBold: isDateBold(
                                                        oneStudentMob.start * 1000,
                                                        0,
                                                    ),
                                                })}
                                            >
                                                {moment(oneStudentMob.start * 1000).format(
                                                    'DD MMM yyyy',
                                                )}
                                            </span>
                                        </div>
                                        <div className="students-mobile-row">
                                            <div className="students-mobile-row-title">
                                                <b>{t('general.endDate')}</b>
                                            </div>

                                            <span
                                                className={classNames({
                                                    isBold: isDateBold(0, oneStudentMob.end * 1000),
                                                })}
                                            >
                                                {moment(oneStudentMob.end * 1000).format(
                                                    'DD MMM yyyy',
                                                )}
                                            </span>
                                        </div>

                                        {layout.isIelts && (
                                            <>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('general.overallScore')}</b>
                                                    </div>
                                                    <b>{oneStudentMob.ielts.scores.overall}</b>
                                                </div>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('general.reading')}</b>
                                                    </div>
                                                    <span>
                                                        {oneStudentMob.ielts.scores.reading || '-'}
                                                    </span>
                                                </div>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('general.listening')}</b>
                                                    </div>
                                                    <span>
                                                        {oneStudentMob.ielts.scores.listening ||
                                                            '-'}
                                                    </span>
                                                </div>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('general.writing')}</b>
                                                    </div>
                                                    <span
                                                        className={classNames('students-writing', {
                                                            'students-active tr_hover_color_text':
                                                                oneStudentMob.ielts.marking.writing,
                                                        })}
                                                        onClick={() => {
                                                            const { writing } =
                                                                oneStudentMob.ielts.marking;
                                                            if (!writing) {
                                                                return;
                                                            }

                                                            const link = `${appConstants.angularIelts}/tracker/writing/practice/${writing.id}/marking/${oneStudentMob.uid}/${writing.attemptId}`;
                                                            window.open(link, '_blank');
                                                        }}
                                                    >
                                                        {oneStudentMob.ielts.scores.writing || '-'}
                                                        {oneStudentMob.ielts.scores.writing || '-'}
                                                    </span>
                                                </div>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('general.speaking')}</b>
                                                    </div>
                                                    <span
                                                        className={classNames('students-writing', {
                                                            'students-active tr_hover_color_text':
                                                                oneStudentMob.ielts.marking
                                                                    .speaking,
                                                        })}
                                                        onClick={() => {
                                                            const { speaking } =
                                                                oneStudentMob.ielts.marking;
                                                            if (!speaking) {
                                                                return;
                                                            }

                                                            const link = `${appConstants.angularIelts}/tracker/results/${speaking.id}/marking/${oneStudentMob.uid}/${speaking.attemptId}`;
                                                            window.open(link, '_blank');
                                                        }}
                                                    >
                                                        {oneStudentMob.ielts.scores.speaking || '-'}
                                                    </span>
                                                </div>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('allStudents.lastAccessedDate')}</b>
                                                    </div>
                                                    <span>
                                                        {oneStudentMob.accessed
                                                            ? moment
                                                                  .unix(oneStudentMob.accessed)
                                                                  .format('DD MMM YYYY')
                                                            : t('general.none').toUpperCase()}
                                                    </span>
                                                </div>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('allStudents.flexCheckCount')}</b>
                                                    </div>
                                                    <span>
                                                        {oneStudentMob.ielts.credits.ai || '-'}
                                                    </span>
                                                </div>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('allStudents.flexLessonCount')}</b>
                                                    </div>
                                                    <span>
                                                        {oneStudentMob.ielts.credits.general || '-'}
                                                    </span>
                                                </div>
                                            </>
                                        )}

                                        {!layout.isIelts && (
                                            <>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('general.age')}</b>
                                                    </div>
                                                    <span>{oneStudentMob.age}</span>
                                                </div>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('general.attendance')}</b>
                                                    </div>
                                                    <span
                                                        className={classNames({
                                                            isBold:
                                                                oneStudentMob.attendance < 80 ||
                                                                oneStudentMob.attendance === null,
                                                        })}
                                                    >
                                                        {oneStudentMob.attendance === null
                                                            ? t('general.NA')
                                                            : `${oneStudentMob.attendance}%`}
                                                    </span>
                                                </div>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('general.tutorial')}</b>
                                                    </div>
                                                    <span
                                                        className={classNames('students-active', {
                                                            isBold: oneStudentMob.tutorial.late,
                                                        })}
                                                        onClick={() => {
                                                            navigate(
                                                                `${appConstants.server}/study/tutorials/add/${oneStudentMob.uid}`,
                                                            );
                                                        }}
                                                    >
                                                        {oneStudentMob.tutorial.text ||
                                                            (oneStudentMob.tutorial.timestamp
                                                                ? moment
                                                                      .unix(
                                                                          oneStudentMob.tutorial
                                                                              .timestamp,
                                                                      )
                                                                      .format('DD MMM yyyy')
                                                                : t('general.none'))}
                                                    </span>
                                                </div>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('general.testStatus')}</b>
                                                    </div>
                                                    <span
                                                        className={classNames({
                                                            isBold:
                                                                oneStudentMob.test.type === 'late',
                                                            'students-active tr_hover_color_text':
                                                                !!oneStudentMob.test.url,
                                                        })}
                                                        onClick={() => {
                                                            if (!oneStudentMob.test.url) {
                                                                return;
                                                            }
                                                            window.open(
                                                                oneStudentMob.test.url,
                                                                '_blank',
                                                                'noopener,noreferrer',
                                                            );
                                                        }}
                                                    >
                                                        {oneStudentMob.test.type === 'available' ? (
                                                            'AVAILABLE'
                                                        ) : oneStudentMob.test.text ===
                                                          'INVITED' ? (
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    gap: '0.5rem',
                                                                }}
                                                            >
                                                                <span>
                                                                    {oneStudentMob.test.text}
                                                                </span>
                                                                <span>
                                                                    {oneStudentMob.test.timestamp &&
                                                                        moment
                                                                            .unix(
                                                                                oneStudentMob!.test!
                                                                                    .timestamp,
                                                                            )
                                                                            .format('DD MMM YYYY')}
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            oneStudentMob.test.text ||
                                                            (oneStudentMob.test.timestamp &&
                                                                moment
                                                                    .unix(
                                                                        oneStudentMob.test
                                                                            .timestamp,
                                                                    )
                                                                    .format('DD MMM YYYY')) ||
                                                            t('general.none').toUpperCase()
                                                        )}
                                                    </span>
                                                </div>
                                                <div className="students-mobile-row">
                                                    <div className="students-mobile-row-title">
                                                        <b>{t('general.survey')}</b>
                                                    </div>
                                                    <CustomLinkOverloaded
                                                        parentProps={{
                                                            className:
                                                                'tr_hover_color_text students-active',
                                                        }}
                                                        linkProps={{
                                                            to: getStudentSurveyUrl(oneStudentMob),
                                                        }}
                                                    >
                                                        {(oneStudentMob.survey &&
                                                            moment
                                                                .unix(
                                                                    oneStudentMob.survey.timestamp,
                                                                )
                                                                .format('DD MMM YYYY')) ||
                                                            t('general.none')}
                                                    </CustomLinkOverloaded>
                                                </div>
                                            </>
                                        )}
                                        <div className="students-mobile-row">
                                            <div className="students-mobile-row-title">
                                                <b>{t('general.note')}</b>
                                            </div>
                                            <span
                                                onClick={() => {
                                                    setUid(oneStudentMob.uid);
                                                    setGetCommentUid(oneStudentMob.uid);
                                                }}
                                                className="students-active students-note-mobile"
                                            >
                                                {getCommentButtonTitle(oneStudentMob)}
                                            </span>
                                        </div>
                                    </>
                                ),
                            };
                        })}
                    />
                    <TablePaginationAPI
                        mob
                        paginationProcessor={paginationProcessor}
                        updatePage={({ page }) => {
                            updateFilter({ page });
                            dispatch(getAllStudents(filter));
                        }}
                    />
                </div>
            </WhiteBox>
            <CommentSaveModal
                open={getCommentUid}
                comment={student.note ?? ''}
                onSave={(textToSave: string) => {
                    dispatch(
                        saveStudentComment({
                            uid,
                            note: textToSave,
                            cb: () => {
                                const studentToUpdateCommentIndex = allStudents.data.findIndex(
                                    (item) => +item.uid === +uid,
                                );
                                const allStudentsTemp = structuredClone(allStudents);
                                allStudentsTemp.data[studentToUpdateCommentIndex].note =
                                    textToSave !== '';
                                dispatch(putAllStudents(allStudentsTemp));
                            },
                        }),
                    );
                    setGetCommentUid(0);
                }}
                onClose={() => {
                    setGetCommentUid(0);
                }}
            />
        </>
    );
};

export default AllStudents;
