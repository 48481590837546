import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cookie from 'react-cookies';
import { startCase } from 'lodash';

import { selectLayout, selectSidebar } from 'src/redux/layout/selectors';
import { appConstants } from 'src/constants';
import Avatar from 'src/components/Icons/Avatar';
import Hamburger from 'src/components/Icons/Hamburger';
import Notifications from 'src/components/Icons/Notifications';
import SettingsModal from 'src/components/Modals/SettingsModal';
import { useOutsideClick } from 'src/hooks/outsideClick';
import CloseButtonSvg from 'src/components/Icons/CloseButtonSvg';
import { sideItems } from 'src/containers/Layout/components/Sidebar/constants';
import { filterSideItems, getUrl } from 'src/containers/Layout/components/Sidebar';
import { useIsModuleActive } from 'src/hooks/useIsModuleActive';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../hooks/useWindowSize';

const Header = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { logoUrl } = useSelector(selectLayout).layout.styling;
    const [settingsModal, setSettingsModal] = useState(false);
    const [openAvatarTooltip, setOpenAvatarTooltip] = useState(false);
    const settingsTooltipRef = useRef(null);
    const [mobileMenu, setMobileMenu] = useState(false);
    const body = document.querySelector(`body`);
    const showSidebar = useSelector(selectSidebar);
    const { isModuleActive, getModuleName } = useIsModuleActive();
    const layout = useSelector(selectLayout);
    const { width } = useWindowSize();
    const filteredSideItems = filterSideItems(
        showSidebar ? sideItems(t)[showSidebar] : [],
        layout,
        isModuleActive,
        width,
    );

    const id = useMemo(() => {
        const locArr = window.location.pathname.split('/');
        return locArr.find((loc) => +loc);
    }, [window.location]);

    useOutsideClick(settingsTooltipRef, () => setOpenAvatarTooltip(false));

    const logOut = () => {
        const subDomain = `.${appConstants.server.split('.').slice(1).join('.')}`;
        const gid = JSON.parse(window.localStorage.getItem('school') ?? '{}')?.gid;
        cookie.remove('API_TOKEN', { domain: subDomain });
        cookie.remove('IELTS_API_TOKEN', { domain: subDomain });
        cookie.remove('PHPSESSID', { domain: subDomain });
        cookie.remove('GELSESSION', { domain: subDomain });
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.open(
            `${appConstants.server}/logout?destination=logout_redirect_do?g=${gid}`,
            '_self',
        );
    };

    useEffect(() => {
        if (mobileMenu) {
            (body as HTMLElement).style.overflow = 'hidden';
        }

        return () => {
            (body as HTMLElement).style.overflow = 'auto';
        };
    }, [mobileMenu]);

    return (
        <div className="header">
            <div className="cursor-pointer" data-testid="header-home" onClick={() => navigate('/')}>
                <img className="header-logo" src={`${appConstants.server}${logoUrl}`} alt="logo" />
            </div>
            <div className="header-icons">
                <div
                    onClick={() => {
                        setOpenAvatarTooltip(!openAvatarTooltip);
                    }}
                    data-testid="open-avatar"
                    style={{ position: 'relative' }}
                    ref={settingsTooltipRef}
                >
                    <Avatar />
                    {openAvatarTooltip && (
                        <div className="tooltip" data-testid="avatar-options">
                            <div
                                className="sc_hover_color_text_svg cursor-pointer"
                                onClick={() => setSettingsModal(true)}
                            >
                                {t('header.settings')}
                            </div>
                            <div
                                className="sc_hover_color_text_svg cursor-pointer"
                                onClick={logOut}
                            >
                                {t('header.logOut')}
                            </div>
                        </div>
                    )}
                </div>
                <div
                    onClick={() => {
                        navigate(
                            appConstants.server
                                ? `${appConstants.server}/history`
                                : `${appConstants.frontendIelts}/history-and-feedback`,
                        );
                    }}
                >
                    <Notifications />
                </div>
            </div>

            {mobileMenu ? (
                <div className="mobile-menu">
                    <div className="mobile-menu__close-button" onClick={() => setMobileMenu(false)}>
                        <CloseButtonSvg />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '2.5rem',
                        }}
                    >
                        <div>{t('header.morePages')}</div>
                        {showSidebar &&
                            filteredSideItems.map((item: Record<string, any>, idx: number) => (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '1rem',
                                    }}
                                    onClick={() => {
                                        const path = getUrl(item.link, showSidebar, id);
                                        if (path !== window.location.pathname) {
                                            navigate(path);
                                        }
                                        setMobileMenu(false);
                                    }}
                                    key={idx}
                                >
                                    {item.icon(false, '#FFF')}
                                    <span
                                        style={{
                                            fontWeight: '700',
                                            lineHeight: '150%',
                                        }}
                                    >
                                        {getModuleName(item.id) || startCase(item.name)}
                                    </span>
                                </div>
                            ))}
                    </div>
                    <div
                        style={{ width: 'calc(100% - 5rem)', height: '1px', background: '#06B6D4' }}
                    />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '1.5rem',
                        }}
                    >
                        <div style={{ marginBottom: '1rem' }}>Account:</div>
                        <div className="cursor-pointer" onClick={() => setSettingsModal(true)}>
                            {t('header.settings')}
                        </div>
                        <div className="cursor-pointer" onClick={logOut}>
                            {t('header.logOut')}
                        </div>
                    </div>
                    <div
                        style={{ width: 'calc(100% - 5rem)', height: '1px', background: '#06B6D4' }}
                    />
                    <div className="mobile-menu__icons">
                        <div
                            onClick={() => {
                                navigate(
                                    appConstants.server !== '//'
                                        ? `${appConstants.server}/history`
                                        : `${appConstants.frontendIelts}/history-and-feedback`,
                                );
                            }}
                        >
                            <Notifications />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="header-icons header-icons-mobile">
                    <div onClick={() => setMobileMenu(true)}>
                        <Hamburger />
                    </div>
                </div>
            )}
            <SettingsModal open={settingsModal} setOpen={setSettingsModal} />
        </div>
    );
};

export default Header;
