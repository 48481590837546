import { appConstants } from 'src/constants';

export const reviewDisabled = (item: any) => {
    const { review } = item;
    const score = item.score && item.score !== 'N/A';
    return (
        (!score && score !== 0 && !review) ||
        (!item.actionType && !review) ||
        ['placement', 'exit'].includes(item.paper) ||
        item.oldFormat ||
        ['weekly_test'].includes(item.context) ||
        item.score === 'N/A' ||
        item.score === null ||
        item.type === 'Assessment'
    );
};

export const linkReview = (track: any, profile: any) => {
    const schoolGid = profile?.school?.gid;
    const demo = [178982, 178983, 149285].includes(schoolGid);
    const isIelts = ['ieltsPlaylist', 'ieltsExercise'].includes(track.actionType);
    let link = '';
    const isIeltsFr = appConstants.frontendIelts !== '//';

    if (track.review) {
        if (demo && track.type === 'Progress Test') {
            return `${appConstants.server}/progress_test/threshold_test`;
        }

        return `${appConstants.server}/progress_test/singledetails/${profile.uid}/${track.scoreId}`;
    }

    if (track.actionType === 'writing') {
        link = isIeltsFr
            ? `/practice/writing/practice/${track.id}/feedback`
            : `/study/ielts/writing/practice/${track.id}/feedback`;
    } else if (track.actionType === 'exercise') {
        link = `/history/exercise/questions/${track.id}/feedback`;
        switch (track.context) {
            case 'auto_test':
                if (demo) {
                    return `${appConstants.server}/progress_test/unit_test`;
                }
                link = `/history/tests/${track.id}/feedback`;
                break;
            default:
                break;
        }
    } else if (track.actionType === 'flashpoint') {
        link = `/history/flashpoint/${track.id}/feedback`;
    } else if (track.actionType === 'speaking') {
        link = isIeltsFr
            ? `/tracker/results/${track.id}`
            : `/study/ielts/speaking/${track.id}/feedback`;
    } else if (isIelts && track.paper.toLowerCase().includes('reading')) {
        const type = track.actionType === 'ieltsPlaylist' ? 'mock' : 'practice';
        link = isIeltsFr
            ? `/${type}/reading/test/${track.id}/feedback`
            : `/study/ielts/reading/${type}/${track.id}/feedback`;
    } else if (isIelts && track.paper.toLowerCase().includes('listening')) {
        const type = track.actionType === 'ieltsPlaylist' ? 'mock' : 'practice';
        link = isIeltsFr
            ? `/${type}/listening/test/${track.id}/feedback`
            : `/study/ielts/listening/${type}/${track.id}/feedback`;
    } else if (isIelts && track.paper.toLowerCase().includes('writing')) {
        link = `/study/ielts/writing/playlist/${track.id}/feedback`;
    } else {
        link = `/study/history/playlist/${track.id}/feedback`;
    }

    return `${isIeltsFr ? appConstants.angularIelts : appConstants.server}${link}/${profile.uid}`;
};
