import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Modal from 'src/components/Modal';
import Table from 'src/components/Table';
import { getNoEmpty } from 'src/helpers/getNoEmpty';
import CheckMark from 'src/components/Icons/CheckMark';
import { Assignment } from 'src/redux/students/types';
import { useParams } from 'react-router-dom';
import { getReportLink } from '../../helpers';
import './styles.scss';

const ProfileInfoAssignmentsModal = ({
    open,
    onClose,
    assignments,
}: {
    open: any;
    onClose: any;
    assignments: Assignment[];
}) => {
    const { t } = useTranslation();
    const params = useParams();

    if (!open) {
        return <></>;
    }

    return (
        <Modal open={open} onClose={() => onClose()} noPadding>
            <div className="profileInfoTableModal assignments">
                <div className="profileInfoTableModal-title">{t('homework.assignments')}</div>
                <Table
                    tableRowsClassName="profileInfoTableModal-table"
                    header={
                        <div className="profile-assignments profile-table-header">
                            <span>{t('profile.type')}</span>
                            <span>{t('profile.name')}</span>
                            <span className="profile-assignments-completed">
                                {t('profile.completed')}
                            </span>
                            <span className="profile-assignments-score">{t('profile.score')}</span>
                            <span className="profile-assignments-date">{t('profile.date')}</span>
                        </div>
                    }
                    rows={assignments.map((result) => {
                        return (
                            <div
                                key={`${result.id}_${result.date}`}
                                className="profile-assignments profile-table-row"
                            >
                                <div className="profile-assignments-type">
                                    {getNoEmpty(result.type || result.actionType)}
                                </div>
                                <div className="profile-assignments-name">{result.title}</div>
                                <div className="profile-assignments-completed">
                                    {/* eslint-disable-next-line no-nested-ternary */}
                                    {result.progress === 100 || result?.completed ? (
                                        <CheckMark />
                                    ) : result.progress ? (
                                        `${result.progress}%`
                                    ) : (
                                        '-'
                                    )}
                                </div>
                                <div className="profile-assignments-score">
                                    {typeof result.score === 'number' ? `${result.score}%` : '-'}
                                </div>
                                <div className="profile-assignments-date">
                                    <span>{moment.unix(result.date).format()}</span>
                                    <span
                                        className="profile-assignments-button"
                                        onClick={() => {
                                            window.open(result.preview);
                                        }}
                                    >
                                        {t('profile.view')}
                                    </span>
                                    <span
                                        className="profile-assignments-button"
                                        onClick={() => {
                                            const url = getReportLink({
                                                id: result.id,
                                                timestamp: result.date,
                                                uid: +params.id!,
                                                type: (result.type ||
                                                    result.actionType)!.toLowerCase() as any,
                                            });
                                            window.open(url);
                                        }}
                                    >
                                        {t('profile.report')}
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                />
            </div>
        </Modal>
    );
};

export default ProfileInfoAssignmentsModal;
