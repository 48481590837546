import { DayLessonActivityType } from 'src/redux/weeklyPlan/types';
import { appConstants } from 'src/constants';

export const getActivitiesHrefSearchAlt = ({ activity }: { activity: DayLessonActivityType }) => {
    let result;
    const links = {
        playlist: `${
            appConstants.frontendIelts !== '//' ? appConstants.frontendIelts : appConstants.frontend
        }/playlist/${activity.id}`,
        exercise:
            appConstants.frontendIelts !== '//'
                ? `${appConstants.frontendIelts}/exercise/${activity.id}`
                : `${appConstants.server}/study/exercise/questions/${activity.id}`,
    };
    result = links[activity.type as 'playlist' | 'exercise'];
    if (!result) {
        result = activity.preview;
    }
    return result;
};

type ActivitiesHrefChartHistogramProps = {
    activity: DayLessonActivityType;
    classId?: string;
    timestamp: number;
};
export const getActivitiesHrefChartHistogram = ({
    activity,
    classId,
    timestamp,
}: ActivitiesHrefChartHistogramProps) => {
    let result;

    const links = {
        playlist: `${appConstants.server}/administration/assignments/playlistreport/${activity.id}/0/0/${classId}/0/0/${timestamp}`,
        exercise: `${appConstants.server}/administration/assignments/playlistreport/0/0/${activity.id}/${classId}/0/0/${timestamp}`,
        assignment: `${appConstants.server}/administration/assignments/reportswitchboard/${activity.id}/1/${classId}/${timestamp}`,
        task: `${appConstants.server}/administration/assignments/ielts_report/writing/${activity.id}/${classId}/${timestamp}`,
        part: `${appConstants.server}/administration/assignments/ielts_report/speaking/${activity.id}/${classId}/${timestamp}`,
        flashPoint: `${appConstants.server}/administration/assignments/playlistreport/0/0/0/${classId}/0/${activity.id}/${timestamp}`,
    };
    result = activity.type && links[activity.type];
    if (!result) {
        result = activity.preview;
    }
    return result;
};
