import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { appConstants } from 'src/constants';
import { classPreview } from '../actions';

export function* classPreviewSaga({ payload }: ReturnType<typeof classPreview>): SagaIterator {
    try {
        const windowReference = window.open();
        yield put(startLoading());
        yield call(API.post(), `/staff/classes/${payload.classId}/preview`);
        if (windowReference) {
            if (appConstants.frontendIelts !== '//') {
                windowReference.location = `${appConstants.frontendIelts}`;
            } else {
                windowReference.location = payload.gel_next
                    ? `${appConstants.frontend}`
                    : `${appConstants.server}/overview`;
            }
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
