import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

import DateSelector from 'src/components/DateSelector';
import Search from 'src/components/Search';
import ClassPreview from 'src/components/ClassPreview';
import WhiteBox from 'src/components/WhiteBox';
import {
    getDaysAndColumnsAfterDelete,
    prepareColumns,
    WEEKLY_PLAN_ITEM_CURATED_OFF,
    WEEKLY_PLAN_ITEM_EDIT_OFF,
} from 'src/containers/WeeklyPlanTable/helpers';
import { EditableColumnType } from 'src/containers/WeeklyPlanTable/types';
import { deleteAutomated } from 'src/redux/automated/actions';
import { putSidebar } from 'src/redux/layout/actions';
import { deleteProgress } from 'src/redux/progress/actions';
import {
    deleteWeekly,
    getBooks,
    getWeeklyPlan,
    putWeeklyPlan,
    updateWeeklyPlan,
} from 'src/redux/weeklyPlan/actions';
import { selectWeeklyPlan, selectWeeklyPlanCuratedHeads } from 'src/redux/weeklyPlan/selectors';
import './styles.scss';
import { useClass } from 'src/hooks/useClass';
import { useWindowSize } from 'src/hooks/useWindowSize';
import WeeklyPlanTableMobile from 'src/containers/WeeklyPlanTable/Mobile';
import RestartClass from 'src/components/Modals/RestartClass';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import { selectLoading } from 'src/redux/loading/selectors';
import './print.scss';
import Bin from 'src/components/Icons/Bin';
import Save from 'src/components/Icons/Save';
import { useCallbackPrompt } from 'src/hooks/useCallbackPrompt';
import MobileControls from 'src/components/MobileControls';
import WeeklyPlanTable from 'src/containers/WeeklyPlanTable';
import {
    DayLessonType,
    WeekDayType,
    WeekLessonType,
    WeeklyPlanType,
} from 'src/redux/weeklyPlan/types';
import { classPreview } from 'src/redux/classes/actions';
import { Pdf } from 'src/containers/WeeklyPlanTable/pdf';
import Export from 'src/components/Icons/Export';
import { selectClass } from 'src/redux/classes/selectors';
import noData1 from 'src/assets/images/noData1.gif';
import { Modules, useIsModuleActive } from 'src/hooks/useIsModuleActive';
import { selectLayout } from 'src/redux/layout/selectors';
import ArrowCounterClockwise from 'src/components/Icons/ArrowCounterClockwise';
import classNames from 'classnames';
import useHistory from 'src/hooks/useHistory';

const WeeklyPlan = () => {
    const { isModuleActive, getModuleName } = useIsModuleActive();
    const params = useParams();
    const dispatch = useDispatch();
    const [activeMode] = useState('teacherText');
    const { lessons, days, name, ...restWeekly } = useSelector(selectWeeklyPlan);
    const [copiedDays, setCopiedDays] = useState(days);
    const { isDesktop } = useWindowSize();
    const [{ week, year }, setDate] = useState({
        week: moment(new Date()).isoWeek(),
        year: moment(new Date()).year(),
    });
    const [testsToDelete, setTestsToDelete] = useState(
        [] as Array<{ type: 'automated' | 'progress'; id: number }>,
    );
    const { t } = useTranslation();

    const [restart, setRestart] = useState(false);
    const [deleteWeek, setDeleteWeek] = useState(false);
    const loading = useSelector(selectLoading);
    const contentIsCurated = useSelector(selectWeeklyPlanCuratedHeads);
    const getSaveButtonTitle = () => {
        if (contentIsCurated.length) {
            return t('weeklyPlan.keepSelectedAndSave');
        }
        return t('general.save');
    };
    const [isDirty, setIsDirty] = useState(false);
    const [showPromptModal, setShowPromptModal] = useState<
        | {
              next: { year: number; week: number };
              prev: { week: number; year: number };
          }
        | boolean
    >(false);
    const classData = useSelector(selectClass);
    const { layout } = useSelector(selectLayout);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isDirty);
    const [isUndoEnabled, setIsUndoEnabled] = useState(false);
    const [isRedoEnabled, setIsRedoEnabled] = useState(false);
    const [savedHistoryData, setSavedHistoryData] = useState<any>(null);
    const [sectionsOpenState, setSectionsOpenState] = useState<{
        [key: string]: { opened: boolean; belongsTo?: 'In Class' | 'Homework' };
    }>({});
    const [prevLessonSubNames, setPrevLessonSubNames] = useState<any>(null);

    const {
        state: historyData,
        setState: setHistoryData,
        resetState: resetHistoryData,
        index: historyDataIndex,
        lastIndex: historyDataLastIndex,
        undo: undoHistoryDataChange,
        redo: redoHistoryDataChange,
    } = useHistory({
        columns: savedHistoryData?.columns || [],
        days: savedHistoryData?.days || [],
    });

    useEffect(() => {
        if (!savedHistoryData) {
            resetHistoryData({
                ...historyData,
                days: days || [],
                columns: prepareColumns(days || []).columns,
            });
        }
    }, [days]);

    useEffect(() => {
        setSavedHistoryData(null);
    }, [week, year]);

    useEffect(() => {
        setIsUndoEnabled(historyDataIndex > 0);
        setIsRedoEnabled(historyDataIndex < historyDataLastIndex);
    }, [historyDataIndex, historyDataLastIndex]);

    useEffect(() => {
        setTestsToDelete([]);
        window.sessionStorage.setItem('weekly-plan-week', week.toString());
        window.sessionStorage.setItem('weekly-plan-year', year.toString());
        if (window.sessionStorage.getItem('restart-class-popup') === 'true') {
            window.sessionStorage.removeItem('restart-class-popup');
            setRestart(isModuleActive(Modules.WeeklyPlannerApplyTemplate));
        }
        if (!isDirty) {
            dispatch(
                getWeeklyPlan({
                    year,
                    week,
                    id: params.id,
                    cb: () => {
                        setRestart(isModuleActive(Modules.WeeklyPlannerApplyTemplate));
                        setIsDirty(false);
                    },
                }),
            );
        }
    }, [week, year, isModuleActive(Modules.WeeklyPlannerApplyTemplate)]);

    useEffect(() => {
        setShowPromptModal(showPrompt);
    }, [showPrompt]);

    const classPreviewCallback = () => {
        dispatch(
            classPreview({ classId: params.id, gel_next: layout.user.fields.gel_next_teacher }),
        );
    };
    useClass();

    useEffect(() => {
        setCopiedDays(historyData.days);
    }, [days, historyData.days]);

    useEffect(() => {
        dispatch(putSidebar('classSelected'));
        dispatch(
            getBooks({
                classId: params.id,
            }),
        );
        return () => {
            dispatch(putSidebar());
            dispatch(putWeeklyPlan({} as WeeklyPlanType));
        };
    }, [dispatch, JSON.stringify(params || {})]);

    useEffect(() => {
        const { lessonSubNames } = prepareColumns(historyData.days);

        if (!isEqual(prevLessonSubNames, lessonSubNames)) {
            setPrevLessonSubNames(lessonSubNames);

            setSectionsOpenState({
                ...Object.keys(lessonSubNames || {}).reduce((acc: any, text) => {
                    const preparedName = text;
                    const belongsTo = text.split('##')[1] === '0' ? 'In Class' : 'Homework';

                    if (preparedName) {
                        acc[preparedName] = {
                            opened: sectionsOpenState[preparedName]
                                ? sectionsOpenState[preparedName].opened
                                : true,
                            belongsTo,
                        };
                    }
                    return acc;
                }, {}),
                homework: {
                    opened: sectionsOpenState.homework ? sectionsOpenState.homework.opened : true,
                    belongsTo: 'Homework',
                },
                homeworkResources: {
                    opened: sectionsOpenState.homeworkResources
                        ? sectionsOpenState.homeworkResources.opened
                        : true,
                },
                tests: {
                    opened: sectionsOpenState.tests ? sectionsOpenState.tests.opened : true,
                },
                zoom: {
                    opened: sectionsOpenState.zoom ? sectionsOpenState.zoom.opened : true,
                },
            });
        }
    }, [historyData.columns]);

    const getUpdatedDays = (data: any, cb: any) => {
        const dataToSave = JSON.parse(JSON.stringify(copiedDays));
        const getDataAttributes = (item: EditableColumnType) => {
            let result = '';
            if (item.editable === WEEKLY_PLAN_ITEM_EDIT_OFF) {
                result += ` data-editable="false" `;
            }
            return result;
        };
        copiedDays.forEach((day, index) => {
            day.lessons.forEach((lesson) => {
                const { lessonId, columnId } = lesson;

                const dataToUpdate = dataToSave[index].lessons.find(
                    ({ columnId: colId, lessonId: lesId }: DayLessonType) =>
                        colId === columnId && lesId === lessonId,
                );
                dataToUpdate.teacherText = '';
                dataToUpdate.studentText = '';

                data[index]
                    .filter(({ columnId: cId, lessonId: lId }: DayLessonType) => {
                        return cId === columnId && lessonId === lId;
                    })
                    .sort((a: any, b: any) => {
                        if (a.head > b.head) {
                            return -1;
                        }
                        if (b.head > a.head) {
                            return 1;
                        }
                        return 0;
                    })
                    .reduce((prev: any, next: any) => {
                        let pContent = '';
                        if (
                            contentIsCurated.includes(next.head) &&
                            next.curated === WEEKLY_PLAN_ITEM_CURATED_OFF
                        ) {
                            pContent = '';
                        } else {
                            pContent = `<p${getDataAttributes(next)}>${next.content}</p>`;
                        }
                        if (prev.head !== next.head) {
                            if (next?.type !== 'teacher') {
                                dataToUpdate.studentText += `<p><strong>${
                                    next.head.split('##')[0]
                                }</strong></p>${pContent}`;
                            } else {
                                dataToUpdate.teacherText += pContent;
                            }
                            return next;
                        }

                        if (prev.type === 'teacher') {
                            dataToUpdate.teacherText += pContent;
                        } else {
                            dataToUpdate.studentText += pContent;
                        }
                        return prev;
                    }, {});
                dataToUpdate.studentText = dataToUpdate.studentText
                    ? `${dataToUpdate.studentText}`
                    : '';
            });
        });
        if (cb) {
            cb(dataToSave);
        }
        return dataToSave;
    };

    const onSave = (cb?: () => void) => {
        const data = historyData.columns;

        if (!contentIsCurated.length) {
            setSavedHistoryData({ days: historyData.days, columns: historyData.columns });
        } else {
            resetHistoryData({
                ...historyData,
            });
        }

        const currentTests = historyData.days
            .map((testdata: any) => {
                return testdata.tests;
            })
            .flat();

        testsToDelete
            .filter((test) => {
                return currentTests.every(({ assignmentId }: any) => test.id !== assignmentId);
            })
            .forEach((test) => {
                if (test.type === 'automated') {
                    dispatch(
                        deleteAutomated({
                            classId: params.id,
                            testId: test.id,
                        }),
                    );
                } else if (test.type === 'progress') {
                    dispatch(
                        deleteProgress({
                            classId: params.id,
                            testId: test.id,
                        }),
                    );
                }
            });
        if (data?.length) {
            getUpdatedDays(data, (dataToSave: any) => {
                dispatch(
                    updateWeeklyPlan({
                        data: {
                            ...restWeekly,
                            days: dataToSave.map((dataDay: any) => {
                                return {
                                    ...dataDay,
                                    lessons: dataDay.lessons.map((dataLesson: any) => {
                                        return {
                                            ...dataLesson,
                                            studentText: dataLesson.studentText.replace(
                                                /(<p><\/p><p><\/p>)(?=.*\1)/g,
                                                '',
                                            ),
                                        };
                                    }),
                                };
                            }),
                            lessons,
                        },
                        classId: params.id,
                        cb,
                    }),
                );
                setIsDirty(false);
            });
        }
    };

    const lessonNames = useMemo(() => {
        const names = {} as any;
        if (!lessons || !lessons[0]) {
            return names;
        }
        lessons[0].lessons.forEach((lesson: any) => {
            names[lesson.name] = {
                columnIds: [],
                lessonIds: [],
                lessonDays: [],
            };
        });

        lessons.forEach((data: any) => {
            data.lessons.forEach((lesson: any) => {
                names[lesson.name].columnIds.push(lesson.columnId);
                names[lesson.name].lessonIds.push(lesson.lessonId);
                names[lesson.name].lessonDays.push({
                    day: data.day,
                    columnId: lesson.columnId,
                    lessonId: lesson.lessonId,
                });
            });
        });

        return names;
    }, [lessons]);

    const generatePdfDocument = async () => {
        const { lessonSubNames } = prepareColumns(historyData.days);

        const blob = await pdf(
            <Pdf
                lessonNames={lessonNames}
                lessonSubNames={lessonSubNames}
                columns={historyData.columns}
                week={week}
                year={year}
                weeklyPlanName={name}
                className={classData.name}
            />,
        ).toBlob();
        saveAs(blob, `Class-${classData.name}_week-${week}.pdf`);
    };

    const pushTestToDelete = (testType: 'automated' | 'progress', testId: number) => {
        const data = historyData.columns;

        getUpdatedDays(data, (cbData: WeekDayType[]) => {
            const daysClone: WeekDayType[] = structuredClone(cbData);
            const tempTestToDelete = [...testsToDelete, { id: testId, type: testType }];
            setTestsToDelete(tempTestToDelete);

            tempTestToDelete.forEach((curTempTest) => {
                daysClone.forEach((dayClone) => {
                    const testIndex = dayClone.tests.findIndex((test) => {
                        return (
                            test.assignmentId === curTempTest.id && test.type === curTempTest.type
                        );
                    });
                    if (testIndex >= 0) {
                        dayClone.tests.splice(testIndex, 1);
                    }
                });
            });
            setHistoryData({ ...historyData, days: daysClone });
        });
    };

    const handleUndoRedoClick = (action: 'undo' | 'redo') => {
        if (action === 'undo' && isUndoEnabled) {
            undoHistoryDataChange();
        }

        if (action === 'redo' && isRedoEnabled) {
            redoHistoryDataChange();
        }
    };

    const deleteDayContent = ({ dayIndex, lesson }: { dayIndex: number; lesson: any }) => {
        const { days: newDays, columns: newColumns } = getDaysAndColumnsAfterDelete({
            index: dayIndex,
            columns: historyData.columns,
            getUpdatedDays,
            lesson,
        });

        setHistoryData({ days: newDays, columns: newColumns });
    };

    if (!copiedDays || !copiedDays.length || !days) {
        return (
            <>
                {name ? <div className="weeklyPlan-header-name">{name}</div> : null}
                {isDesktop && (
                    <div className="weeklyPlan-header">
                        <div className="weeklyPlan-header-left">
                            {isModuleActive(Modules.WeeklyPlannerSearch) && <Search />}
                            {isModuleActive(Modules.WeeklyPlannerViewAsStudent) && (
                                <ClassPreview callback={classPreviewCallback} />
                            )}
                        </div>
                        <div className="weeklyPlan-header-right">
                            <DateSelector
                                weekNumber={week}
                                year={year}
                                onChange={(weekNumber, yearNumber) => {
                                    setDate({
                                        year: yearNumber,
                                        week: weekNumber,
                                    });
                                }}
                            />
                            <div
                                className="weeklyPlan-header-right-icon weeklyPlan-header__save-icon pr_hover_color_text_svg_bg"
                                onClick={() => {
                                    onSave();
                                    setIsDirty(false);
                                }}
                            >
                                <Save />
                                {getSaveButtonTitle()}
                            </div>
                            {isModuleActive(Modules.WeeklyPlannerExport) && (
                                <div
                                    className="weeklyPlan-header-right-icon pr_hover_color_text_svg_bg  weeklyPlan-header__save-icon"
                                    style={{ opacity: 0.5, cursor: 'not-allowed' }}
                                >
                                    <Export />
                                    <span>{t('general.export')}</span>
                                </div>
                            )}
                            <div
                                className={classNames('button-element', {
                                    disabled: !isUndoEnabled,
                                })}
                                onClick={() => handleUndoRedoClick('undo')}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        transform: 'scale(-1, 1)',
                                    }}
                                >
                                    <ArrowCounterClockwise />
                                </div>
                                {t('general.undo')}
                            </div>
                            <div
                                className={classNames('button-element', {
                                    disabled: !isRedoEnabled,
                                })}
                                onClick={() => handleUndoRedoClick('redo')}
                            >
                                <ArrowCounterClockwise />
                                {t('general.redo')}
                            </div>
                            {isModuleActive(Modules.WeeklyPlannerApplyTemplate) && (
                                <div
                                    className="weeklyPlan-restart pr_hover_color_text_svg_bg"
                                    onClick={() => {
                                        setRestart(true);
                                        setIsDirty(false);
                                    }}
                                >
                                    {getModuleName(Modules.WeeklyPlannerApplyTemplate) ||
                                        t('weeklyPlan.changeBookOrUnit')}
                                </div>
                            )}
                            <div
                                className="weeklyPlan-header-right-icon pr_hover_color_text_svg_bg"
                                onClick={() => {
                                    setDeleteWeek(true);
                                    setIsDirty(false);
                                }}
                            >
                                <Bin />
                            </div>
                        </div>
                    </div>
                )}
                <MobileControls>
                    {isModuleActive(Modules.WeeklyPlannerSearch) && <Search />}
                    <DateSelector
                        weekNumber={week}
                        year={year}
                        onChange={(weekNumber, yearNumber) => {
                            setDate({
                                year: yearNumber,
                                week: weekNumber,
                            });
                        }}
                    />
                    <div
                        className="weeklyPlan-header-right-icon weeklyPlan-header__save-icon pr_hover_color_text_svg_bg"
                        onClick={() => {
                            onSave();
                        }}
                    >
                        <Save />
                        {getSaveButtonTitle()}
                    </div>
                    {isModuleActive(Modules.WeeklyPlannerExport) && (
                        <div
                            className="weeklyPlan-header-right-icon pr_hover_color_text_svg_bg  weeklyPlan-header__save-icon"
                            style={{ opacity: 0.5, cursor: 'not-allowed' }}
                        >
                            <Export />
                            <span>{t('general.export')}</span>
                        </div>
                    )}
                    {isModuleActive(Modules.WeeklyPlannerViewAsStudent) && (
                        <ClassPreview callback={classPreviewCallback} />
                    )}
                    {isModuleActive(Modules.WeeklyPlannerApplyTemplate) && (
                        <div className="weeklyPlan-restart pr_hover_color_text_svg_bg">
                            <span onClick={() => setRestart(true)}>
                                {t('weeklyPlan.changeBookOrUnit')}
                            </span>
                        </div>
                    )}
                    <div
                        className={classNames('button-element', {
                            disabled: !isUndoEnabled,
                        })}
                        onClick={() => handleUndoRedoClick('undo')}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                transform: 'scale(-1, 1)',
                            }}
                        >
                            <ArrowCounterClockwise />
                        </div>
                    </div>
                    <div
                        className={classNames('button-element', {
                            disabled: !isRedoEnabled,
                        })}
                        onClick={() => handleUndoRedoClick('redo')}
                    >
                        <ArrowCounterClockwise />
                    </div>
                    <div
                        className="weeklyPlan-header-right-icon"
                        onClick={() => {
                            setDeleteWeek(true);
                        }}
                    >
                        <Bin />
                    </div>
                </MobileControls>
                <WhiteBox>
                    <>
                        {!loading && (
                            <div className="table-noData">
                                <img src={noData1} alt="noData" />
                                <span>
                                    {t('weeklyPlan.nothingPlannedThisWeek')}.
                                    <br />
                                    {t('weeklyPlan.beginChangeBookOrUnit')}
                                </span>
                            </div>
                        )}
                    </>
                </WhiteBox>
                <RestartClass
                    open={restart}
                    onClose={() => setRestart(false)}
                    onSubmit={() => {
                        setSavedHistoryData(null);
                    }}
                    week={week}
                    year={year}
                />
            </>
        );
    }

    return (
        <>
            {name ? <div className="weeklyPlan-header-name">{name}</div> : null}
            {isDesktop && (
                <div className="weeklyPlan-header">
                    <div className="weeklyPlan-header-left">
                        {isModuleActive(Modules.WeeklyPlannerSearch) && <Search />}
                        {isModuleActive(Modules.WeeklyPlannerViewAsStudent) && (
                            <ClassPreview callback={classPreviewCallback} />
                        )}
                    </div>
                    <div className="weeklyPlan-header-right">
                        <DateSelector
                            weekNumber={week}
                            year={year}
                            onChange={(weekNumber, yearNumber) => {
                                if (isDirty) {
                                    setShowPromptModal({
                                        next: {
                                            year: yearNumber,
                                            week: weekNumber,
                                        },
                                        prev: { week, year },
                                    } as any);
                                    setDate({
                                        year: yearNumber,
                                        week: weekNumber,
                                    });
                                    return;
                                }
                                setDate({
                                    year: yearNumber,
                                    week: weekNumber,
                                });
                            }}
                        />
                        <div
                            className="weeklyPlan-header-right-icon weeklyPlan-header__save-icon pr_hover_color_text_svg_bg"
                            onClick={() => {
                                onSave();
                                setIsDirty(false);
                            }}
                        >
                            <Save />
                            {getSaveButtonTitle()}
                        </div>
                        {isModuleActive(Modules.WeeklyPlannerExport) && (
                            <div
                                className="weeklyPlan-header-right-icon pr_hover_color_text_svg_bg  weeklyPlan-header__save-icon"
                                onClick={generatePdfDocument}
                            >
                                <Export />
                                <span>{t('general.export')}</span>
                            </div>
                        )}
                        {isModuleActive(Modules.WeeklyPlannerApplyTemplate) && (
                            <div
                                className="weeklyPlan-restart pr_hover_color_text_svg_bg"
                                onClick={() => {
                                    setRestart(true);
                                    setIsDirty(false);
                                }}
                            >
                                {getModuleName(Modules.WeeklyPlannerApplyTemplate) ||
                                    t('weeklyPlan.changeBookOrUnit')}
                            </div>
                        )}
                        <div
                            className={classNames('button-element', {
                                disabled: !isUndoEnabled,
                            })}
                            onClick={() => handleUndoRedoClick('undo')}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    transform: 'scale(-1, 1)',
                                }}
                            >
                                <ArrowCounterClockwise />
                            </div>
                            {t('general.undo')}
                        </div>
                        <div
                            className={classNames('button-element', {
                                disabled: !isRedoEnabled,
                            })}
                            onClick={() => handleUndoRedoClick('redo')}
                        >
                            <ArrowCounterClockwise />
                            {t('general.redo')}
                        </div>
                        <div
                            className="weeklyPlan-header-right-icon pr_hover_color_text_svg_bg"
                            onClick={() => {
                                setDeleteWeek(true);
                                setIsDirty(false);
                            }}
                        >
                            <Bin />
                        </div>
                    </div>
                </div>
            )}
            <MobileControls>
                {isModuleActive(Modules.WeeklyPlannerSearch) && <Search />}
                <DateSelector
                    weekNumber={week}
                    year={year}
                    onChange={(weekNumber, yearNumber) => {
                        if (isDirty) {
                            setShowPromptModal({
                                next: {
                                    year: yearNumber,
                                    week: weekNumber,
                                },
                                prev: { week, year },
                            } as any);
                            setDate({
                                year: yearNumber,
                                week: weekNumber,
                            });
                            return;
                        }
                        setDate({
                            year: yearNumber,
                            week: weekNumber,
                        });
                    }}
                />
                <div
                    className="weeklyPlan-header-right-icon weeklyPlan-header__save-icon pr_hover_color_text_svg_bg"
                    onClick={() => {
                        onSave();
                        setIsDirty(false);
                    }}
                >
                    <Save />
                    {getSaveButtonTitle()}
                </div>
                {isModuleActive(Modules.WeeklyPlannerExport) && (
                    <div
                        className="weeklyPlan-header-right-icon pr_hover_color_text_svg_bg  weeklyPlan-header__save-icon"
                        onClick={generatePdfDocument}
                    >
                        <Export />
                        <span>{t('general.export')}</span>
                    </div>
                )}
                {isModuleActive(Modules.WeeklyPlannerViewAsStudent) && (
                    <ClassPreview callback={classPreviewCallback} />
                )}
                <div
                    className={classNames('button-element', {
                        disabled: !isUndoEnabled,
                    })}
                    onClick={() => handleUndoRedoClick('undo')}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            transform: 'scale(-1, 1)',
                        }}
                    >
                        <ArrowCounterClockwise />
                    </div>
                </div>
                <div
                    className={classNames('button-element', {
                        disabled: !isRedoEnabled,
                    })}
                    onClick={() => handleUndoRedoClick('redo')}
                >
                    <ArrowCounterClockwise />
                </div>
                {isModuleActive(Modules.WeeklyPlannerApplyTemplate) && (
                    <div className="weeklyPlan-restart pr_hover_color_text_svg_bg">
                        <span
                            onClick={() => {
                                setRestart(true);
                                setIsDirty(false);
                            }}
                        >
                            {getModuleName(Modules.WeeklyPlannerApplyTemplate) ||
                                t('weeklyPlan.changeBookOrUnit')}
                        </span>
                    </div>
                )}
                <div
                    className="weeklyPlan-header-right-icon pr_hover_color_text_svg_bg"
                    onClick={() => {
                        setDeleteWeek(true);
                        setIsDirty(false);
                    }}
                >
                    <Bin />
                </div>
            </MobileControls>
            {isDesktop ? (
                <WeeklyPlanTable
                    activeMode={activeMode}
                    week={week}
                    year={year}
                    lessons={lessons}
                    days={historyData.days || []}
                    setDays={(updatedDays) => setHistoryData({ ...historyData, days: updatedDays })}
                    getUpdatedDays={getUpdatedDays}
                    restWeekly={restWeekly}
                    setIsDirty={setIsDirty}
                    onSave={onSave}
                    pushTestToDelete={pushTestToDelete}
                    columns={historyData.columns}
                    setColumns={(updatedColumns) =>
                        setHistoryData({ ...historyData, columns: updatedColumns })
                    }
                    deleteDayContent={({
                        dayIndex,
                        lesson,
                    }: {
                        dayIndex: number;
                        lesson: WeekLessonType;
                    }) => deleteDayContent({ dayIndex, lesson })}
                    sectionsOpenState={sectionsOpenState}
                    setSectionsOpenState={setSectionsOpenState}
                    setSavedHistoryData={setSavedHistoryData}
                />
            ) : (
                <WeeklyPlanTableMobile
                    activeMode={activeMode}
                    week={week}
                    year={year}
                    lessons={lessons}
                    days={historyData.days}
                    setDays={(updatedDays) => setHistoryData({ ...historyData, days: updatedDays })}
                    getUpdatedDays={getUpdatedDays}
                    restWeekly={restWeekly}
                    setIsDirty={setIsDirty}
                    onSave={onSave}
                    pushTestToDelete={pushTestToDelete}
                    columns={historyData.columns}
                    setColumns={(updatedColumns) =>
                        setHistoryData({ ...historyData, columns: updatedColumns })
                    }
                    deleteDayContent={({
                        dayIndex,
                        lesson,
                    }: {
                        dayIndex: number;
                        lesson: WeekLessonType;
                    }) => deleteDayContent({ dayIndex, lesson })}
                    sectionsOpenState={sectionsOpenState}
                    setSectionsOpenState={setSectionsOpenState}
                    setSavedHistoryData={setSavedHistoryData}
                />
            )}

            <RestartClass
                open={restart}
                onClose={() => setRestart(false)}
                onSubmit={() => {
                    setSavedHistoryData(null);
                }}
                week={week}
                year={year}
            />
            <ConfirmModal
                text={t('weeklyPlan.deleteWeekConfirmation')}
                onClick={() => {
                    dispatch(
                        deleteWeekly({
                            week,
                            year,
                            classId: params.id,
                        }),
                    );
                    setDeleteWeek(false);
                    setIsDirty(false);
                }}
                onClose={() => {
                    setDeleteWeek(false);
                }}
                open={deleteWeek}
            />
            <ConfirmModal
                text={t('general.unsavedChangesConfirmation')}
                onClick={() => {
                    if (typeof showPromptModal === 'object') {
                        setDate(showPromptModal.next);
                        dispatch(
                            getWeeklyPlan({
                                ...showPromptModal.next,
                                id: params.id,
                                cb: () => {
                                    setIsDirty(false);
                                },
                            }),
                        );
                        setShowPromptModal(false);
                        setIsDirty(false);
                        return;
                    }
                    confirmNavigation();
                }}
                onClose={() => {
                    if (typeof showPromptModal === 'object') {
                        setDate(showPromptModal.prev);
                        setShowPromptModal(false);
                        return;
                    }
                    cancelNavigation();
                }}
                open={Boolean(showPromptModal)}
            />
        </>
    );
};

export default WeeklyPlan;
